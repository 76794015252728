import {
  compose, values, prop, reduce, props, add, filter, path, keys, length,
  pipe, identity, pathOr, when, assocPath, map, complement, isEmpty, last, propOr,
} from 'ramda';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';

import {
  TWENTY_ONE, PERFECT_PAIR, MAIN_BET, SERVER_PERFECT_PAIR, SERVER_TEN_TWENTY, SERVER_PERFECT_ELEVEN,
  SERVER_TWENTY_ONE, INSURANCE_BET, ANTE_BET, SERVER_ANTE_BET, PERFECT_ELEVEN, TEN_TWENTY,
} from '../../../constants/betTypes';
import {
  currentBetsSelector, userSelector, tableIdSelector, roundIdSelector, gameSelector,
} from '../../selectors';
import betActions from '../../actions/bets';
import { NOTIFICATION_MSG, } from '../../constants/notifications';
import { isSplitSeat, } from '../../../views/GameSeats/utils';

const { notificationActions, } = bootstrapActions;

export const getTotalBet = pipe(
  values,
  reduce(
    (acc, curr) => ({
      [MAIN_BET]: +(pathOr(0, [ MAIN_BET, ], acc) + pathOr(0, [ MAIN_BET, 'value', ], curr)).toFixed(2),
      [ANTE_BET]: +(pathOr(0, [ ANTE_BET, ], acc) + pathOr(0, [ ANTE_BET, 'value', ], curr)).toFixed(2),
      [PERFECT_PAIR]: +(pathOr(0, [ PERFECT_PAIR, ], acc) + pathOr(0, [ PERFECT_PAIR, 'value', ], curr)).toFixed(2),
      [PERFECT_ELEVEN]: +(pathOr(0, [ PERFECT_ELEVEN, ],
        acc) + pathOr(0, [ PERFECT_ELEVEN, 'value', ], curr)).toFixed(2),
      [TEN_TWENTY]: +(pathOr(0, [ TEN_TWENTY, ], acc) + pathOr(0, [ TEN_TWENTY, 'value', ], curr)).toFixed(2),
      [TWENTY_ONE]: +(pathOr(0, [ TWENTY_ONE, ], acc) + pathOr(0, [ TWENTY_ONE, 'value', ], curr)).toFixed(2),
      [INSURANCE_BET]: +(pathOr(0, [ INSURANCE_BET, ], acc) + pathOr(0, [ MAIN_BET, INSURANCE_BET, ], curr)).toFixed(2),
    }),
    {},
  ),
  props([ MAIN_BET, ANTE_BET, TWENTY_ONE, PERFECT_PAIR, PERFECT_ELEVEN, TEN_TWENTY, INSURANCE_BET, ]),
  filter(identity),
  reduce(add, 0),
);

export const getCurrSeatMainBetValue = (bet, currentBets) => pathOr(0, [ bet.index, MAIN_BET, 'value', ], currentBets);
export const extractBetValue = propOr(0, 'value');

/**
 * Given mainBet value and anteRanges, this method will return the correspondent anteBet value
 * @return {Number} ante bet value
 * @example
 * anteRanges: {
  '100': '1',
  '200': '2',
  '300': '3',
  '400': '4',
  '500': '5',
  '600': '6'
}
 */
export const getAnteBetValue = (mainBetValue = 0, anteRanges = {}) => {
  const ZERO_ANTE = 0;
  const ANTERANGE = {
    LIMIT: 0,
    VALUE: 1,
  };

  const anteRange = Object.entries(anteRanges)
    .find((upperLimit) => mainBetValue <= +upperLimit[ANTERANGE.LIMIT]) || last(Object.entries(anteRanges));

  return compose(Number, propOr(ZERO_ANTE, ANTERANGE.VALUE))(anteRange);
};

export const buildParams = (bets, state) => {
  const user = userSelector(state);

  return {
    ClientId: user.clientId,
    ClientIP: user.clientIp,
    CurrentPlayerToken: user.currentPlayerToken,
    MessageType: 'PlaceBet',
    destination: 'table',
    gameType: gameSelector(state).serverName,
    TableId: tableIdSelector(state).toString(),
    roundId: roundIdSelector(state),
    RegularBets: bets,
  };
};

export const getTotalNoOfBets = pipe(
  reduce((acc, curr) => [ ...acc, ...keys(curr), ], []),
  length
);

const getBetsFromList = reduce((acc, curr) => ({
  ...acc,
  [curr.main.index]: curr,
}), {});

export const keepValidBets = pipe(
  values,
  map(filter(prop('valid'))),
  filter(complement(isEmpty))
);

export const createBetRequestPayload = (state) => {
  const currentBetsList = compose(
    values,
    currentBetsSelector
  )(state);

  const actions = [];

  if (currentBetsList.length) {
    const validBetsList = keepValidBets(currentBetsList);

    if (validBetsList.length) {
      const betsToSend = reduce(
        (acc, { main: { value, index, }, perfectEleven = {}, tenTwenty = {},
          perfectPair = {}, twentyOne = {}, ante = {}, }) => {
          const res = pipe(
            () => ({
              BetAmount: value,
              SeatId: index,
              SideBets: {},
            }),
            when(() => path([ 'value', ], tenTwenty),
              assocPath([ 'SideBets', SERVER_TEN_TWENTY, ], tenTwenty.value)),
            when(() => path([ 'value', ], perfectEleven),
              assocPath([ 'SideBets', SERVER_PERFECT_ELEVEN, ], perfectEleven.value)),
            when(() => path([ 'value', ], perfectPair),
              assocPath([ 'SideBets', SERVER_PERFECT_PAIR, ], perfectPair.value)),
            when(() => path([ 'value', ], twentyOne),
              assocPath([ 'SideBets', SERVER_TWENTY_ONE, ], twentyOne.value)),
            when(() => path([ 'value', ], ante),
              assocPath([ 'SideBets', SERVER_ANTE_BET, ], ante.value)),
          )();

          return [
            ...acc,
            res,
          ];
        },
        [],
        validBetsList
      );
      const params = buildParams(betsToSend, state);
      actions.push(betActions.bet.request(params));
    }
    const totalBet = getTotalBet(validBetsList);
    if (getTotalNoOfBets(currentBetsList) > getTotalNoOfBets(validBetsList)) {
      actions.push(notificationActions.notification.add({ message: NOTIFICATION_MSG.BET_BELOW_MIN_LIMITS_REMOVED, }));
      actions.push(betActions.bet.apply({ current: getBetsFromList(validBetsList), }));
      actions.push(betActions.totalBet.set({ value: totalBet, }));
    }
    // cache valid bets for rebet
    const validBets = validBetsList.reduce((prev, next) => ({
      ...prev,
      // remove split seat bets
      ...(!isSplitSeat({ seatId: next.main.index, }) ? { [next.main.index]: next, } : {}),
    }), {});
    actions.push(betActions.bet.cache({ current: validBets, totalBet: getTotalBet(validBets), }));
  }
  return actions;
};
