import { BET_TYPES, PAYOUTS, } from '../constants';

export default (name, limits, payouts = PAYOUTS) => {
  switch (name) {
  case BET_TYPES.BASIC_BLACKJACK:
    return {
      name,
      locale: 'blackjack_ui.limit_basic_blackjack',
      min: limits.Min_Bet,
      max: limits.Max_Bet,
    };
  case BET_TYPES.BlackJack:
    return {
      name,
      locale: 'blackjack_ui.blackjackpayout',
      payout: payouts.BlackJack,
    };
  case BET_TYPES.PLAYER_WINS:
    return {
      name,
      locale: 'blackjack_ui.playerwins',
      payout: payouts.PlayerWins,
    };
  case BET_TYPES.INSURANCE:
    return {
      name,
      locale: 'blackjack_ui.insurancepayout',
      payout: payouts.Insurance,
    };
  case BET_TYPES.BET_BEHIND:
    return {
      name,
      locale: 'blackjack_ui.bet_behind',
      min: limits.Min_BetBehind,
      max: limits.Max_BetBehind,
      label: 'blackjack_ui.betBehindLabel',
    };
  case BET_TYPES.BET_BEHIND_LABEL:
    return {
      name,
      locale: 'blackjack_ui.bet_behind_label',
    };
  case BET_TYPES.PERFECT_PAIR_SIDE_BET:
    return {
      name,
      locale: 'blackjack_ui.perfect_pair_side_bet',
      min: limits.Min_SideBet,
      max: limits.Max_SideBet,
    };
  case BET_TYPES.PERFECT_PAIR:
    return {
      name,
      locale: 'blackjack_ui.perfect_pair',
      payout: payouts.PerfectPair,
    };
  case BET_TYPES.ANY_ELEVEN:
    return {
      name,
      locale: 'blackjack_ui.any_eleven',
      payout: payouts.AnyEleven,
    };
  case BET_TYPES.PERFECT_ELEVEN_BLACKJACK:
    return {
      name,
      locale: 'blackjack_ui.perfect_eleven_blackjack',
      payout: payouts.PerfectElevenBlackjack,
    };
  case BET_TYPES.PERFECT_ELEVEN_SIDE_BET:
    return {
      name,
      locale: 'blackjack_ui.perfect_eleven_side_bet',
      min: limits.Min_SideBet,
      max: limits.Max_SideBet,
    };
  case BET_TYPES.PERFECT_ELEVEN:
    return {
      name,
      locale: 'blackjack_ui.perfect_eleven',
      payout: payouts.PerfectEleven,
    };
  case BET_TYPES.TEN_TWENTY_SIDE_BET:
    return {
      name,
      locale: 'blackjack_ui.ten_twenty_side_bet',
      min: limits.Min_SideBet,
      max: limits.Max_SideBet,
    };
  case BET_TYPES.TEN_TWENTY:
    return {
      name,
      locale: 'blackjack_ui.ten_twenty_pair_of_5',
      payout: payouts.TenTwenty,
    };
  case BET_TYPES.TOTAL_TEN:
    return {
      name,
      locale: 'blackjack_ui.ten_twenty_total_ten',
      payout: payouts.TotalTen,
    };
  case BET_TYPES.TOTAL_TWENTY:
    return {
      name,
      locale: 'blackjack_ui.ten_twenty_total_twenty',
      payout: payouts.TotalTwenty,
    };
  case BET_TYPES.COLORED_PAIR:
    return {
      name,
      locale: 'blackjack_ui.colored_pair',
      payout: payouts.ColoredPair,
    };
  case BET_TYPES.MIXED_PAIR:
    return {
      name,
      locale: 'blackjack_ui.mixed_pair',
      payout: payouts.MixedPair,
    };
  case BET_TYPES.SIDE_BETS_21_PLUS_3:
    return {
      name,
      locale: 'blackjack_ui.side_bet_21_plus_3',
      min: limits.Min_SideBet,
      max: limits.Max_SideBet,
    };
  case BET_TYPES.SUITED_TRIPS:
    return {
      name,
      locale: 'blackjack_ui.suited_trips',
      payout: payouts.SuitedTrips,
    };
  case BET_TYPES.STRAIGHT_FLUSH:
    return {
      name,
      locale: 'blackjack_ui.straight_flush',
      payout: payouts.StraightFlush,
    };
  case BET_TYPES.THREE_OF_A_KIND:
    return {
      name,
      locale: 'blackjack_ui.three_of_a_kind',
      payout: payouts.ThreeOfAKind,
    };
  case BET_TYPES.STRAIGHT:
    return {
      name,
      locale: 'blackjack_ui.straight',
      payout: payouts.Straight,
    };
  case BET_TYPES.FLUSH:
    return {
      name,
      locale: 'blackjack_ui.flush',
      payout: payouts.Flush,
    };
  default: {
    return { name, min: 0, max: 0, payout: 0, };
  }
  }
};
