import { tap, ignoreElements, } from 'rxjs/operators';
import { ofType, } from 'redux-observable';
import { setPropertyVideoWidth, } from './utils';
import videoActions from '../../actions/video';

const { video, } = videoActions;
const videoPlayEpic = (action$) => action$.pipe(
  ofType(video.play),
  tap(setPropertyVideoWidth),
  ignoreElements()
);

export default videoPlayEpic;
