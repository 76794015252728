import { mapPropNames, getVideoElement, } from '@ezugi/utils';
import { GAME_RESULT, NO_MORE_BETS, } from '@ezugi/constants';
import humps from 'humps';
import qs from 'qs';

import { videoConfigSelector, definitionsSelector, } from '../../selectors';
import { isHandheld, } from '../../../util';

export const fetchCDNList = (state) => {
  const { gameId, playerId, tableId, operatorId, identifier, } = videoConfigSelector(state);
  const { VIDEO_CDN_SERVICE, } = definitionsSelector(state) || {};

  const params = qs.stringify({
    gameID: gameId,
    playerID: playerId,
    TableID: tableId,
    operatorID: operatorId,
    /* eslint-disable-next-line camelcase */
    video_type: 'nano',
    identifier,
  });

  return fetch(`${VIDEO_CDN_SERVICE}?${params}`)
    .then((res) => res.json())
    .catch((err) => Promise.reject(err));
};

export const camelize = mapPropNames(humps.camelize);

export const isNoMoreBets = (roundStatus) => [ GAME_RESULT, NO_MORE_BETS, ].includes(roundStatus);

export function setPropertyVideoWidth() {
  const videoElement = getVideoElement();
  if (!videoElement || !isHandheld) return;
  document.body.style.setProperty('--video-width', `${videoElement.offsetWidth}px`);
}
