import winSvg from '../../assets/icons/round-result-win-icon.svg';
import loseSvg from '../../assets/icons/round-result-lose-icon.svg';
import pushSvg from '../../assets/icons/round-result-push-icon.svg';
// UBJ
import winUBJSvg from '../../assets/icons/round-result-ubj-win-icon.svg';

export const RESULT_MAP = {
  Win: winSvg,
  Lose: loseSvg,
  Push: pushSvg,
};

export const UBJ_RESULT_MAP = {
  Win: winUBJSvg,
  Lose: loseSvg,
  Push: pushSvg,
};

export const RESULT_ICON_SIZE = {
  DESKTOP: 44,
  MOBILE: 26,
};

export const ROUND_TOTAL = {
  BLACKJACK: 'BlackJack',
  BUST: 'Bust',
  PUSH: 'Push',
  SURRENDER: 'Surrender',
};
