/* eslint-disable no-shadow */
import { of, } from 'rxjs';
import { camelizeKeys, } from 'humps';
import {
  filter, contains, pipe, isEmpty, isNil,
} from 'ramda';
import { ls, } from '@ezugi/utils';
import { LOADING, } from '@ezugi/constants';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import { NO_WELCOME_DIALOG, WELCOME_DIALOG, } from '../../../views/GameDialog/dialogs/constants';

import { parseJson, } from '../../../util/epicsUtils';
import {
  SERVER_MAIN_BET,
  SERVER_PERFECT_PAIR,
  SERVER_TWENTY_ONE,
  SERVER_DOUBLE_BET,
  SERVER_SPLIT_BET,
  SERVER_INSURANCE_BET,
  SERVER_ANTE_BET,
  SERVER_PERFECT_ELEVEN,
  SERVER_TEN_TWENTY,
} from '../../../constants/betTypes';
import playersActions from '../../actions/players';
import { extractWonSidebets, } from './handleWonBets';
import { isUBJonURL, } from './utils';

const { seats, players, } = playersActions;
const {
  socketActions: { socket, },
  videoActions: { video, },
  roundActions: { round, },
  gameActions: { game, },
  settingsActions: { settings, },
  betActions: { bet, },
  dialogActions: { dialog, },
  tableActions: { table, },
} = bootstrapActions;

const getBetTypeValue = (data, type) => data
  .filter(({ betName, }) => contains(betName, type))
  .reduce((acc, { betAmount, }) => acc + betAmount, 0);

const getBetsValsFromServerRes = ({ bets, seatId: index, }) => {
  const data = camelizeKeys(bets);
  const valid = true;

  const value = getBetTypeValue(data, [
    SERVER_DOUBLE_BET,
    SERVER_MAIN_BET,
    // ignore SPLIT bet on UBJ, because engine sends split seats bets along with main bet
    ...(isUBJonURL ? [] : [ SERVER_SPLIT_BET, ]),
  ]);
  const insurance = getBetTypeValue(data, [ SERVER_INSURANCE_BET, ]);
  const perfectPair = data.find(({ betName, }) => betName === SERVER_PERFECT_PAIR);
  const twentyOne = data.find(({ betName, }) => betName === SERVER_TWENTY_ONE);
  const perfectEleven = data.find(({ betName, }) => betName === SERVER_PERFECT_ELEVEN);
  const tenTwenty = data.find(({ betName, }) => betName === SERVER_TEN_TWENTY);
  const ante = data.find(({ betName, }) => betName === SERVER_ANTE_BET);

  return {
    main: {
      valid,
      index,
      value,
      ...(insurance && { insurance, }),
    },
    ...(perfectPair && { perfectPair: {
      valid,
      index,
      value: perfectPair.betAmount,
    }, }),
    ...(twentyOne && { twentyOne: {
      valid,
      index,
      value: twentyOne.betAmount,
    }, }),
    ...(perfectEleven && { perfectEleven: {
      valid,
      index,
      value: perfectEleven.betAmount,
    }, }),
    ...(tenTwenty && { tenTwenty: {
      valid,
      index,
      value: tenTwenty.betAmount,
    }, }),
    ...(ante && { ante: {
      valid,
      index,
      value: ante.betAmount,
    }, }),
  };
};

export const getBets = pipe(
  (seats) => seats.reduce(
    (acc, { BetsList: bets, SeatId: seatId, }) => {
      const { main, perfectPair, twentyOne,
        perfectEleven, tenTwenty, ante, } = getBetsValsFromServerRes({ bets, seatId, });

      return {
        ...acc,
        [seatId]: {
          main,
          ...(!isNil(perfectPair) && {
            perfectPair,
          }),
          ...(!isNil(twentyOne) && {
            twentyOne,
          }),
          ...(!isNil(perfectEleven) && {
            perfectEleven,
          }),
          ...(!isNil(tenTwenty) && {
            tenTwenty,
          }),
          ...(!isNil(ante) && {
            ante,
          }),
        },
      };
    },
    {}
  ),
);

const getOtherPlayerBets = (seat) => {
  const mappedSeat = camelizeKeys(seat);
  if (isNil(mappedSeat.betsList) || isEmpty(mappedSeat.betsList)) {
    return mappedSeat;
  }
  return {
    ...mappedSeat,
    betsList: { ...getBetsValsFromServerRes({
      bets: mappedSeat.betsList,
      seatId: mappedSeat.seatId,
    }), },
  };
};

const [ err, noWelcomeDialog, ] = ls.get(NO_WELCOME_DIALOG);

export default function handleInitialData(socketMessage) {
  const {
    VideoStreamData,
    roundId,
    timeStamp,
    RoundTripStartTime,
    History,
    AvailableSeats,
    ClientId,
    CurrentPlayingSeatName,
    WonSideBets,
    AnteRanges,
    MaxFreeRounds,
  } = socketMessage;
  const userSeats = filter(({ PlayerId, }) => PlayerId === ClientId)(AvailableSeats) || [];
  const bets = !isEmpty(userSeats)
    ? pipe(
      filter((seat) => !isNil(seat.BetsList) && !isEmpty(seat.BetsList)),
      getBets,
    )(userSeats)
    : {};
  let allSeats = AvailableSeats.map(getOtherPlayerBets);
  // if UBJ, seat the player automatically
  if (isUBJonURL) {
    allSeats = allSeats.map(({ seatId, ...rest }) => ({
      ...rest,
      ...{
        seatId,
        taken: true,
        playerId: [ 's1', 's1-2', ].includes(seatId) ? ClientId : null,
      },
    }));
  }

  return of(
    socket.success(socketMessage),
    settings.init(),
    video.set({ streamData: VideoStreamData, }),
    round.set({
      roundStatus: LOADING,
      roundId,
      roundTime: RoundTripStartTime,
      timestamp: timeStamp,
      isDealNow: userSeats.some((seat) => seat.IsDealNow) || undefined, // !important
    }),
    // FIXME check if this is the correct store for history
    game.set({
      gameResults: {
        History,
        ...(isUBJonURL && !isNil(WonSideBets) ? { wonSidebets: extractWonSidebets(WonSideBets), } : {}),
      },
      isFreeRoundsEnabled: MaxFreeRounds > 0,
    }),
    players.set({
      playerTurn: {
        seatId: CurrentPlayingSeatName,
        clientId: ClientId,
      },
    }),
    seats.set(allSeats),
    ...(userSeats.length > 0 ? [ seats.setActiveSeatId(userSeats[0].SeatId), ] : []),
    bet.apply({ current: bets, }),
    // tutorial dialog available only for UBJ
    ...(isUBJonURL && (err || !noWelcomeDialog) ? [ dialog.add({ name: WELCOME_DIALOG, }), ] : []),
    table.set({ anteRanges: parseJson(AnteRanges) || {}, }),
  );
}
