import { handleActions, } from 'redux-actions';
import {
  merge, isEmpty, last, isNil, compose, not, sortBy, prop, head,
} from 'ramda';

import { isUBJonURL, } from '../epics/socket/utils';

import playersActions from '../actions/players';
import seatsActions from '../actions/seat';
import decisionActions from '../actions/decision';

import { SEATS, } from '../../constants';
import { BACK_CARD_NAME, } from '../../primitives/Card/constants';
import { PLAYER_CALLED_DOUBLE, } from '../constants/decision';
import { CARD_TYPES_MAP, } from '../constants/seats';
import { isSplitSeat, } from '../../views/GameSeats/utils';
import { isUBJSelector, } from '../selectors';

const { seats, userSeats, playerTurn, players, } = playersActions;
const { temporaryUnclickable, } = seatsActions;
const { decision, } = decisionActions;

const defaultSeat = {
  gameResultStatus: null,
  taken: false,
  playerId: null,
  nickname: '',
  seatId: null,
  active: false,
  type: SEATS.SEAT_TYPES.NEW_SEAT_FREE,
  betAmount: 0,
  reservedSeat: false,
  callBetDecision: null,
  betsList: [],
  cards: [],
  extraCardsList: [],
  earlyDecision: null,
  availableEarlyDecisions: [],
  unclickable: false,
};

const initialState = {
  seats: [],
  dealer: {},
  playerTurn: {},
  callBets: {
    decisions: [],
  },
  // ids of the user seats, used for calculating if rebet is possible
  userSeats: [],
};

export default handleActions(
  {
    [players.set]: (state, { payload, }) => ({
      ...state,
      ...payload,
    }),
    [temporaryUnclickable.set]: (state, { payload, }) => {
      const currentSeats = [ ...state.seats, ];

      return {
        ...state,
        seats: currentSeats.map((seat) => merge(seat, {
          unclickable: true,
          ...payload,
        })),
      };
    },
    [temporaryUnclickable.reset]: (state, { payload, }) => {
      const currentSeats = [ ...state.seats, ];

      return {
        ...state,
        seats: currentSeats.map((seat) => merge(seat, { ...payload, })),
      };
    },
    [seats.update]: (state, { payload, }) => {
      const currentSeats = [ ...state.seats, ];
      const { seatId, } = payload;

      return {
        ...state,
        seats: currentSeats.map((seat) => {
          if (seat.seatId === seatId) {
            return merge(seat, {
              ...payload,
              taken: true,
              type: SEATS.SEAT_TYPES.SEAT_TAKEN,
            });
          }
          return seat;
        }),
      };
    },
    [seats.concat]: (state, { payload, }) => ({
      ...state,
      seats: sortBy(prop('seatId'))(state.seats.concat(payload)),
    }),
    [seats.status]: (state, { payload, }) => ({
      ...state,
      seats: state.seats.map((s) => ({ ...s, gameResultStatus: payload[s.seatId], })),
    }),
    [seats.add]: (state, { payload, }) => {
      const currentSeats = [ ...state.seats, ];
      const cardsUpdateStore = head(Object.keys(payload));
      const cards = payload[cardsUpdateStore];

      return {
        ...state,
        seats: currentSeats.map((seat) => {
          if (seat.seatId === cards.seatId) {
            // check for back card in stack and remove it
            if (!isEmpty(seat.cards) && last(seat.cards).cardName === BACK_CARD_NAME) seat.cards.pop();
            return {
              ...seat,
              [cardsUpdateStore]: seat[cardsUpdateStore].concat({
                ...payload[cardsUpdateStore],
                double: seat.callBetDecision === PLAYER_CALLED_DOUBLE,
              }),
            };
          }
          return seat;
        }),
      };
    },
    [seats.clean]: (state) => {
      const currentSeats = state.seats.filter(compose(not, isSplitSeat));

      return {
        ...state,
        seats: currentSeats.map((seat) => ({
          ...seat,
          cards: [],
          cardsList: [],
          extraCardsList: [],
          betsList: {},
          gameResultStatus: null,
          earlyDecision: null,
          availableEarlyDecisions: [],
          insuranceDecision: null,
          callBetDecision: null,
        })),
      };
    },
    [seats.clearCards]: (state, { payload = {}, }) => ({
      ...state,
      seats: state.seats.map((seat) => !payload.seatIds || payload.seatIds.includes(seat.seatId) ? ({
        ...seat,
        cards: [],
        cardsList: [],
        extraCardsList: [],
        gameResultStatus: null,
        earlyDecision: null,
        availableEarlyDecisions: [],
        insuranceDecision: null,
        callBetDecision: null,
      }) : seat),
    }),
    [seats.cancelLastCard]: (state, { payload = {}, }) => ({
      ...state,
      seats: state.seats.map((seat) => {
        if (payload.SeatId === seat.seatId) {
          const cardListPropertyName = CARD_TYPES_MAP[payload.CardRelevance]
                ?? (seat[CARD_TYPES_MAP.Extra]?.length > 0 ? CARD_TYPES_MAP.Extra : CARD_TYPES_MAP.Relevant);
          return ({
            ...seat,
            [cardListPropertyName]: seat[cardListPropertyName]?.slice?.(0, -1),
          });
        }
        return seat;
      }),
    }),
    [seats.set]: (state, { payload, }) => ({
      ...state,
      seats: (payload.map((seat) => ({
        ...merge(defaultSeat, seat),
        cards: (!isNil(seat.cardsList)) // reconnect case
          ? [
            ...seat.cardsList.map((card) => ({
              cardName: card,
              cardValue: card,
              totalCardValues: seat.totalCardValue,
              seatId: seat.seatId,
              double: seat.callBetDecision === PLAYER_CALLED_DOUBLE,
            })),
          ] : [],
        ...(isUBJonURL || isUBJSelector(state) ? {
          extraCardsList: !isNil(seat.extraCardsList) // reconnect case
            ? [
              ...seat.extraCardsList.map((card) => ({
                cardName: card,
                cardValue: card,
                totalCardValues: seat.totalCardValue,
                seatId: seat.seatId,
                double: seat.callBetDecision === PLAYER_CALLED_DOUBLE,
              })),
            ] : [],
        } : {}),
        type: seat.taken ? SEATS.SEAT_TYPES.SEAT_TAKEN : SEATS.SEAT_TYPES.NEW_SEAT_FREE,
      }))).sort((a, b) => (a.seatId[1] || 0) - (b.seatId[1] || 0)),
    }),
    [seats.remove]: (state, { payload, }) => {
      const currentSeats = [ ...state.seats, ];
      return {
        ...state,
        seats: currentSeats.map((seat) => (seat.seatId === payload.seatId ? {
          ...defaultSeat,
          seatId: seat.seatId,
        } : seat)),
      };
    },
    [seats.setActiveSeatId]: (state, { payload, }) => ({
      ...state,
      activeSeatId: payload,
    }),
    [userSeats.cache]: (state, { payload, }) => ({
      ...state,
      userSeats: payload,
    }),
    [userSeats.clean]: (state) => ({
      ...state,
      userSeats: [],
    }),
    [decision.early]: (state, { payload: { seatId, earlyDecision, }, }) => {
      const currentSeats = [ ...state.seats, ];

      return {
        ...state,
        seats: currentSeats.map((seat) => (seat.seatId === seatId ? {
          ...seat,
          earlyDecision,
        } : seat)),
      };
    },
    [playerTurn.set]: (state, { payload, }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
);
