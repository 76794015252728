import {
  MAIN_BET, PERFECT_ELEVEN, PERFECT_PAIR, TEN_TWENTY, TWENTY_ONE,
} from '../../constants/betTypes';

export const PERFECT_PAIR_ID = 'perfectPairBet';
export const PERFECT_ELEVEN_ID = 'perfectElevenBet';
export const TEN_TWENTY_ID = 'tenTwentyBet';
export const TWENTY_ONE_ID = 'twentyOneBet';
export const MAIN_BET_ID = 'mainBet';
export const SVG_CLASS = 'betsSvg';
export const betsIdsMap = {
  [MAIN_BET_ID]: MAIN_BET,
  [PERFECT_PAIR_ID]: PERFECT_PAIR,
  [TWENTY_ONE_ID]: TWENTY_ONE,
  [PERFECT_ELEVEN_ID]: PERFECT_ELEVEN,
  [TEN_TWENTY_ID]: TEN_TWENTY,
};
