import {
  flatMap, map, pluck, filter, delay,
} from 'rxjs/operators';
import { concat, of, } from 'rxjs';
import { combineEpics, ofType, } from 'redux-observable';
import { equals, isEmpty, includes, } from 'ramda';
import { NO_MORE_BETS, PLACE_YOUR_BETS, INSURANCE_DECISION, } from '@ezugi/constants';
import { actions as bootstrapActions, constants, selectors as bootstrapSelectors, } from '@ezugi/bootstrap';

import { createSeatRequestPayload, createSeatsScoresNormalizedPayload, } from './utils/request';
import {
  LEAVE_SEAT, PLAYER_TURN, TAKE_SEAT, SEAT_RESULT,
} from './constants';

import {
  minBetSelector, roundStatusSelector, totalBetSelector,
  userBalanceSelector, videoDelaySelector, gameResultsSelector, playerSeatsWithInsuranceCallSelector,
  seatsSelector, playerSeatsIdsSelector, playerSeatsCountSelector, freeSeatsSelector, maxNoOfSeatsPerPlayerSelector,
} from '../../selectors';

import seatActions from '../../actions/seat';
import playersActions from '../../actions/players';
import { INSURANCE_CALLS, } from '../../../components/Insurance/constants';
import { ANIMATION_DURATION as DELAY_GAME_CLEANUP, TIMER_DELAY, } from '../../../util/animations';


const {
  DIALOG: { LOW_BALANCE_DIALOG, },
} = constants;
const {
  socketActions: { socket, },
  roundActions: { round, },
  uiActions: { progressBar, },
} = bootstrapActions;
const { seats, playerTurn, } = playersActions;
const { seat, temporaryUnclickable, } = seatActions;
const {
  dialogActions: { dialog, },
} = bootstrapActions;
const { roundSelector, } = bootstrapSelectors;

const takeAllSeatsRequestEpic = (action$, state$) => action$.pipe(
  ofType(seat.takeall),
  flatMap(() => {
    const freeSeats = freeSeatsSelector(state$.value);
    const maxSeats = maxNoOfSeatsPerPlayerSelector(state$.value);
    const playerTakenSeatsCount = playerSeatsCountSelector(state$.value);
    const availableSeats = freeSeats.slice(0, Math.max(maxSeats - playerTakenSeatsCount, 0));
    const actions = availableSeats.map(
      ({ seatId, }) => seat.request(createSeatRequestPayload(TAKE_SEAT, seatId, state$.value))
    );

    return of(...actions);
  }),
);

const takeSeatRequestEpic = (action$, state$) => action$.pipe(
  ofType(seat.take),
  filter(() => !roundSelector(state$.value)?.isDealNow),
  pluck('payload', 'value'),
  flatMap((seatId) => {
    const minBetLimit = minBetSelector(state$.value);
    const userBalance = userBalanceSelector(state$.value);
    const totalBet = totalBetSelector(state$.value);
    // don't allow player to take seat if he has insufficient funds
    const roundStatus = roundStatusSelector(state$.value);
    if (userBalance < minBetLimit
      || (roundStatus === PLACE_YOUR_BETS && userBalance - totalBet < minBetLimit)) {
      return of(dialog.add({ name: LOW_BALANCE_DIALOG, }));
    }
    const takeSeatReqObj = createSeatRequestPayload(TAKE_SEAT, seatId, state$.value);
    return concat(of(seat.request({ ...takeSeatReqObj, })), of(temporaryUnclickable.set({ unclickable: true, })));
  }),
);

const leaveSeatRequestEpic = (action$, state$) => action$.pipe(
  ofType(seat.leave),
  filter(() => !roundSelector(state$.value)?.isDealNow),
  pluck('payload', 'value'),
  map((seatId) => {
    const leaveSeatReqObj = createSeatRequestPayload(LEAVE_SEAT, seatId, state$.value);
    return seat.request({ ...leaveSeatReqObj, });
  })
);

const normalizeScoreEpic = (action$, state$) => action$.pipe(
  ofType(seats.normalizeScores),
  pluck('payload'),
  flatMap(({ playerSeat, }) => {
    const actions = [];
    const seatsScoreNormalized = createSeatsScoresNormalizedPayload(playerSeat, state$.value);
    if (!isEmpty(seatsScoreNormalized)) {
      seatsScoreNormalized.forEach((normalizedSeat) => actions.push(seats.update(normalizedSeat)));
    }

    return of(...actions);
  }),
);

const placeYourBetsEpic = (action$, store$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  filter(({ MessageType, }) => equals(MessageType, PLACE_YOUR_BETS)),
  flatMap(() => {
    const state = store$.value;
    return concat(
      of(round.set({
        gameResults: {
          ...gameResultsSelector(state),
          seats: [],
        },
      })),
      of(seats.clean()).pipe(delay(DELAY_GAME_CLEANUP)),
      // of(game.clean()).pipe(delay(DELAY_GAME_CLEANUP))
    );
  }),
);

const getTimerLeftWithDelay = (state, timer) => Math.floor(timer - videoDelaySelector(state));
const insuranceEpic = (action$, state$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  filter(({ MessageType, }) => includes(MessageType, [ INSURANCE_CALLS, INSURANCE_DECISION, ])),
  flatMap(({
    MessageType,
    RoundTripStartTime,
    timeStamp,
    TimerTimeLeft,
    SeatId,
    InsuranceCalls,
    BetAmount,
    InsuranceDecision,
  }) => {
    const state = state$.value;
    if (MessageType === INSURANCE_CALLS) {
      return of(
        round.set({
          roundStatus: INSURANCE_CALLS,
          roundTime: RoundTripStartTime,
          timestamp: timeStamp,
          timeLeft: getTimerLeftWithDelay(state, TimerTimeLeft),
          totalTime: TimerTimeLeft,
          insurance: {
            prompt: true,
            timestamp: Date.now(),
          },
        }),
        seats.update({
          seatId: SeatId,
          insuranceCall: {
            options: InsuranceCalls,
            amount: BetAmount,
          },
        }),
      ).pipe(delay(TIMER_DELAY));
    }

    const seatsWithInsuranceCall = playerSeatsWithInsuranceCallSelector(state);
    const playerSeatsIds = playerSeatsIdsSelector(state);
    const endInsuranceCalls = seatsWithInsuranceCall.length === 1 && seatsWithInsuranceCall[0].seatId === SeatId;

    return of(
      ...(endInsuranceCalls ? [
        progressBar.set({ label: undefined, }),
      ] : []),
      round.set({
        ...(endInsuranceCalls && { roundStatus: NO_MORE_BETS, }),
        // only update when it receives the `INSURANCE_DECISION` for own seats
        // otherwise `prompt` would be set to `true`
        ...(playerSeatsIds.includes(SeatId) && {
          insurance: {
            prompt: !endInsuranceCalls,
            calledInsurance: endInsuranceCalls,
          },
        }),
      }),
      seats.update({
        seatId: SeatId,
        insuranceDecision: InsuranceDecision,
        insuranceCall: null,
      }),
      seats.normalizeScores({
        playerSeat: {
          seatId: SeatId,
        },
      }),
    );
  }),
);

const playerTurnEpic = (action$, state$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  filter(({ MessageType, }) => equals(MessageType, PLAYER_TURN)),
  flatMap(({ ClientId, SeatId, }) => {
    const allSeats = seatsSelector(state$.value);
    const currentTurnSeat = SeatId === 'd' ? undefined : allSeats?.find?.((s) => s.seatId === SeatId);

    return of(
      playerTurn.set({
        playerTurn: {
          clientId: ClientId,
          seatId: SeatId,
          playerId: currentTurnSeat?.playerId,
          nickname: currentTurnSeat?.nickname,
        },
      })
    ).pipe(delay(DELAY_GAME_CLEANUP));
  })
);

/* const firstHandInfoEpic = (action$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  filter(({ MessageType, }) => equals(MessageType, FIRST_HAND_INFORMATION)),
  flatMap(({ SeatsCardsAndTotalValues, }) => {
    const options = SeatsCardsAndTotalValues.filter((seatOptions) => seatOptions.SeatId && seatOptions.CallBets);
    const actions = options.map((info) => seats.update({
      seatId: info.SeatId,
      availableEarlyDecisions: info.CallBets,
    }));

    return of(...actions);
  }),
); */

const seatResultEpic = (action$, state$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  filter(({ MessageType, }) => equals(MessageType, SEAT_RESULT)),
  flatMap(({ SeatResult, WinAmount, }) => {
    const state = state$.value;
    return of(
      round.set({
        gameResults: {
          ...gameResultsSelector(state),
          seats: gameResultsSelector(state).seats.concat({
            SeatResult,
            WinAmount,
          }),
        },
      })
    );
  }),
);

export default combineEpics(
  takeSeatRequestEpic,
  takeAllSeatsRequestEpic,
  leaveSeatRequestEpic,
  normalizeScoreEpic,
  placeYourBetsEpic,
  insuranceEpic,
  playerTurnEpic,
  // firstHandInfoEpic,
  seatResultEpic,
);
