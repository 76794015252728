import { concat, of, } from 'rxjs';
import playersActions from '../../actions/players';

const { seats, } = playersActions;

export default function handleCancelLastCardMessage(socketMessage) {
  const { SeatId, } = socketMessage;

  return concat(
    of(
      seats.cancelLastCard(socketMessage),
      seats.normalizeScores({
        playerSeat: {
          seatId: SeatId,
        },
      })
    )
  );
}
