import { createSelector, } from 'reselect';

export const localeSelector = (state) => state.locale;

export const daysLocaleSelector = createSelector(
  localeSelector,
  (locale) => locale.days,
);

export const monthsLocaleSelector = createSelector(
  localeSelector,
  (locale) => locale.months,
);

export const gamesLocaleSelector = createSelector(
  localeSelector,
  (locale) => locale.games,
);

export const uiLocaleSelector = createSelector(
  localeSelector,
  (locale) => locale.blackjack_ui,
);

export const messagesLocaleSelector = createSelector(
  localeSelector,
  (locale) => locale.messages,
);
