import { createActions, } from 'redux-actions';
import { identity, } from 'ramda';

export default createActions({
  VIDEO: {
    REQUEST: null,
    FAILURE: null,
    SUCCESS: null,
    SET: null,
    READY: null,
    INIT: null,
    PLAY: [ identity, (_, meta) => meta, ],
    PAUSE: [ identity, (_, meta) => meta, ],
    STOP: null,
    BUFFER: null,
    ERROR: null,
    DESTROY: null,
    RECONNECT: null,
    DISCONNECT: null,
  },
});
