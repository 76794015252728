import { createActions, } from 'redux-actions';

export default createActions({
  USER_SEATS: {
    CACHE: null,
    CLEAN: null,
  },
  SEATS: {
    REQUEST: null,
    SUCCESS: null,
    FAILURE: null,
    UPDATE: null,
    REMOVE: null,
    SET: null,
    ADD: null,
    CLEAN: null,
    CONCAT: null,
    STATUS: null,
    NORMALIZE_SCORES: null,
    CLEAR_CARDS: null,
    CANCEL_LAST_CARD: null,
    SET_ACTIVE_SEAT_ID: null,
  },
  PLAYER: {
    UPDATE: null,
    SET: null,
  },
  PLAYERS: {
    SET: null,
  },
  PLAYER_TURN: {
    SET: null,
  },
});
