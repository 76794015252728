import { isEmpty, propOr, } from 'ramda';
import { actions as bootstrapActions, constants, selectors, } from '@ezugi/bootstrap';

import {
  userBalanceSelector,
  currentBetsSelector,
  enableAnteSelector,
} from '../selectors';
import { getTotalBet, } from '../epics/bets/utils';
import { VALID, INVALID_BALANCE, OVER_TABLE_LIMIT, } from './status';


const { generalConfigSelector, } = selectors;

const {
  dialogActions: { dialog, },
} = bootstrapActions;
const {
  DIALOG: { LOW_BALANCE_DIALOG, },
} = constants;

export const validateBalance = (bet, state) => {
  const balance = userBalanceSelector(state);
  const currentBets = currentBetsSelector(state);
  const totalBet = getTotalBet(currentBets);
  const enableAnte = enableAnteSelector(state);
  const { betAutoAdjust, } = generalConfigSelector(state);

  const minBetValue = betAutoAdjust ? 0 : bet.value;

  // bet isEmpty when placed bet is OVER_TABLE_LIMIT
  const ok = !isEmpty(bet) ? balance - totalBet >= minBetValue : true;
  const status = ok ? (isEmpty(bet) && enableAnte ? OVER_TABLE_LIMIT : VALID) : INVALID_BALANCE;
  const actions = !ok ? [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ] : [];
  return {
    ok,
    valid: ok,
    status,
    bet,
    actions,
  };
};
