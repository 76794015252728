import React from 'react';
import { setTheme, theme, } from '@ezugi/bootstrap';
import {
  CALL_BETS_DECISION, CURRENT_BALANCE, VIDEO_MODERATION_RESULT, INSURANCE_DECISION,
} from '@ezugi/constants';
import { injectGlobal, css, } from 'react-emotion';
import { cx, } from 'emotion';
import {
  TAKE_SEAT,
  LEAVE_SEAT,
  SEAT_TAKEN,
} from './store/epics/seats/constants';

import {
  isBJonURL, isBJSPonURL, isBJVConURL, isUBJonURL,
} from './store/epics/socket/utils';
import handleOperatorError from './store/epics/socket/handleOperatorError';

import {
  betsEpic,
  betsReducer,
  decisionEpic,
  gameEpic,
  gameReducer,
  insuranceEpic,
  playersEpic,
  playersReducer,
  roundReducer,
  seatsEpic,
  socketEpic,
  splitHand,
  dealNowEpic,
  analyticsEpic,
  videoEpic,
} from './store/index';

import { resultMessageClassName, } from './components/ResultMessage/styles';
import DealNowButton from './components/DealNowButton';
import { GAME_VIDEO_BEFORE_BG, } from './views/GameSeatsUBJ/constants';
import { videoContainerUBJ, } from './views/GameSeatsUBJ/style';
import { isHandheld, } from './util';

if (isBJSPonURL) {
  import('./salonprive/assets/coins.gif').then((m) => {
    const src = m.default;
    const image = new Image();

    image.onload = () => {};
    image.onerror = image.onabort = () => {};
    image.src = src;
  });

  import('./assets/fonts/Domine.ttf').then((m) => {
    m?.default && injectGlobal`
      @font-face {
        font-family: 'Domine';
        src: url(${m.default}) format('truetype');
      }
    `;
  });

  import('./assets/fonts/DMSerifDisplay-Regular.ttf').then((m) => {
    m?.default && injectGlobal`
      @font-face {
        font-family: 'DMSerifDisplay';
        src: url(${m.default}) format('truetype');
      }
    `;
  });

  setTheme({
    palette: {
      background: '#000',
      foreground: '#FFF',
      primary: '#000',
      primaryContrast: '#FFF',
      felt: '#392037',
      feltContrast: '#000',
      accent: '#F9DEA5',
      accentContrast: '#000',
    },
  });
}

// states that will no be affected by the videoDelay
const promptStates = [
  TAKE_SEAT,
  LEAVE_SEAT,
  SEAT_TAKEN,
  CALL_BETS_DECISION,
  CURRENT_BALANCE,
  VIDEO_MODERATION_RESULT,
  INSURANCE_DECISION,
];

export default () => ({
  promptStates,
  components: {
    GameBoard: () => isBJVConURL
      ? import('./components/VideoChat')
      : isBJSPonURL
        ? import('./salonprive/GameBoard')
        : Promise.resolve({ default: () => null, }),
    // ResultMessage: () => Promise.resolve({ default: () => null, }),
    VideoOverlay: () => import('./views/VideoOverlay'),
    Payouts: () => import('./components/Payouts'),
    LeftSideDrawerContent: isBJSPonURL
      ? () => Promise.resolve({ default: () => null, })
      : () => import('./components/CardsStack/GameCardsAndTotalValues'),
    BoardToggle: () => Promise.resolve({ default: () => null, }),
    CallBetsToolbar: isBJSPonURL
      ? () => Promise.resolve({ default: () => null, })
      : () => import('./views/GameWidgets'),
    MobileDecisions: isBJSPonURL
      ? () => Promise.resolve({ default: () => null, })
      : () => import('./views/GameWidgets'),
    Settings: () => import('./components/Widgets/Settings'),
    GameTools: isBJSPonURL ? () => import('./salonprive/GameTools') : undefined,
    BetToolbar: isBJSPonURL ? () => import('./salonprive/GameTools') : undefined,
    GameResults: isBJSPonURL ? () => import('./salonprive/GameResults') : undefined,
    WelcomeDialog: () => import('./components/WelcomeDialog'),
    LowBalanceBetOnAllDialog: () => import('./salonprive/LowBalanceBetOnAllDialog'),
    GameInfo: () => import('./components/GameInfo'),
    Preloader: isBJSPonURL ? () => import('./salonprive/Preloader') : undefined,
    GameHeader: isBJSPonURL ? () => import('./salonprive/GameHeader') : undefined,
    GameFooter: isBJSPonURL ? () => import('./salonprive/GameFooter') : undefined,
  },
  store: {
    epics: [
      socketEpic,
      betsEpic,
      seatsEpic,
      gameEpic,
      playersEpic,
      insuranceEpic,
      decisionEpic,
      splitHand,
      dealNowEpic,
      ...(isBJonURL ? [ analyticsEpic, ] : []),
      videoEpic,
    ],
    reducers: {
      players: playersReducer,
      round: roundReducer,
      bets: betsReducer,
      game: gameReducer,
    },
  },
  props: {
    GameLeftSideDrawer: { style: { top: '-220px', }, },
    GameTools: { trailingNMB: true, },
    ResultMessage: { className: resultMessageClassName, },
    BetToolbar: { children: <DealNowButton />, },
    GameUI: { id: 'game-ui', },
    GameVideo: {
      className:
      cx(
        css`
        &:before {
          content: '';
          width: 100vw;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
          background: ${isUBJonURL ? GAME_VIDEO_BEFORE_BG : theme.palette.felt};
          z-index: -1;
        }
      `, { [videoContainerUBJ]: isHandheld && isUBJonURL, }
      ),
    },
    ...(isBJSPonURL
      ? {
        GameLobby: {
          style: { top: 0, left: 'initial', },
          align: 'right',
        },

      }
      : {}
    ),
  },
  hocs: {
    VideoContainer: () => import('./hocs/VideoContainer'),
  },
  gameBoardHeight: 22,
  desktopBoardToggle: false,
  hideLeftSideDrawer: false,
  showMobileFooter: !!isBJSPonURL,
  landscapeToolbarOrientation: isBJSPonURL ? 'horizontal' : 'vertical',
  handleOperatorError,
});
