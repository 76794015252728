/* eslint-disable eqeqeq */
import { createSelector, } from 'reselect';
import {
  path,
  prop,
  pathOr,
  filter,
  compose,
  propOr,
  when,
} from 'ramda';

import { selectors as bootstrapSelectors, } from '@ezugi/bootstrap';

const {
  gameIdSelector,
} = bootstrapSelectors;

const configSelector = propOr({}, 'config');
export const betAutoAdjustSelector = createSelector(configSelector, path([ 'general', 'betAutoAdjust', ]));
/**
 * Contains all game relevant ids such as gameId, limitId, tableId
 */
export const gameSelector = pathOr({}, [ 'game', ]);
export const selectedChipSelector = path([ 'game', 'selectedChip', ]);
export const userSelector = prop('user');
export const userNicknameSelector = path([ 'user', 'nickname', ]);
// Default currency to $
// TODO: check if this default should apply
export const userCurrencySelector = pathOr('$', [ 'user', 'currency', ]);
export const userBalanceSelector = pathOr(0, [ 'user', 'balance', ]);
export const clientIdSelector = path([ 'user', 'clientId', ]);

export const chatSelector = propOr({}, 'chat');
export const isChatMutedSelector = createSelector(chatSelector, prop('mute'));
export const roomMessagesSelector = createSelector(chatSelector, userSelector, (chat, user) => compose(
  when(() => chat.mute, filter(({ name, }) => name === user.nickname)),
  pathOr([], [ 'room', 'messages', ])
)(chat));
export const supportMessagesSelector = createSelector(chatSelector, path([ 'support', 'messages', ]));

export const volumeSelector = path([ 'media', 'volume', ]);
export const soundSettingsSelector = path([ 'media', 'sound', ]);

export const gameSettingsSelector = pathOr({}, [ 'game', 'settings', ]);
export const greetingSelector = pathOr(false, [ 'game', 'greeting', ]);

export const isFreeRoundsEnabledSelector = createSelector(
  gameSelector,
  ({ isFreeRoundsEnabled, }) => isFreeRoundsEnabled
);

export const sidebetsSettingsSelector = createSelector(
  gameSettingsSelector,
  ({ hideSidebets = false, }) => hideSidebets,
);

export const autoStandSelector = createSelector(
  gameSettingsSelector,
  ({ autoStand = false, }) => autoStand,
);

// TODO: below game check selectors to be moved to @ezugi/bootstrap into selectors/index.js
export const isBJSelector = createSelector(
  gameIdSelector,
  (gameId) => gameId == 1
);

export const isABJSelector = createSelector(
  gameIdSelector,
  (gameId) => gameId == 10
);

export const isUBJSelector = createSelector(
  gameIdSelector,
  (gameId) => gameId == 12
);

export const isBJVCSelector = createSelector(
  gameIdSelector,
  (gameId) => gameId == 51
);

export const isBJSPSelector = createSelector(
  gameIdSelector,
  (gameId) => gameId == 46
);
