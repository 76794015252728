/* eslint-disable no-shadow */
import {
  distinctUntilChanged, filter, concatMap, flatMap, map, pluck, skipUntil, take,
} from 'rxjs/operators';
import { concat, of, EMPTY, } from 'rxjs';
import { combineEpics, ofType, } from 'redux-observable';
import {
  allPass,
  assocPath,
  divide,
  equals,
  find,
  head,
  isEmpty,
  keys,
  last,
  length,
  lensPath,
  map as rMap,
  pathOr,
  prop,
  propEq,
  propOr,
  set,
  values,
  view,
} from 'ramda';
import { GAME_RESULT, NO_MORE_BETS, PLACE_YOUR_BETS, } from '@ezugi/constants';
import { actions as bootstrapActions, selectors, } from '@ezugi/bootstrap';
import {
  autoStandSelector,
  enableAutoStandSelector,
  currentBetsSelector,
  isDealNowSelector,
  isUBJSelector,
  nonDealerSeatsSelector,
  videoDelaySelector,
  rebetNextRoundSelector,
} from '../../selectors';


import {
  validateBets, validateBetUndo, validateDouble, validateRebet, validateBetOnAll, validateRebetNextRound,
} from '../../validations';
import { createBetRequestPayload, } from './utils';

import betActions from '../../actions/bets';
import playersActions from '../../actions/players';
import decisionActions from '../../actions/decision';
import { CALL_BET_DECISION, CALL_BETS, } from '../seats/constants';
import {
  PLAYER_CALLED_DO_NOT_SPLIT,
  PLAYER_CALLED_DOUBLE,
  PLAYER_CALLED_SPLIT,
  PLAYER_CALLED_STAND,
  STAND,
  SPLIT,
} from '../../constants/decision';
import { isAce, } from '../../../primitives/Card/utils';
import { CARD_TYPES_MAP, } from '../../constants/seats';

const { roundSelector, } = selectors;
const {
  roundActions,
  socketActions: { socket, },
  betActions: { bet, history, },
} = bootstrapActions;
const { round, } = roundActions;
const { seats, players, playerTurn, } = playersActions;
const {
  mainBet,
  twentyOne,
  perfectPair,
  perfectEleven,
  tenTwenty,
  bet: { repeat: betOnAll, },
  bet: betBJ,
} = betActions;
const { decision, } = decisionActions;

const betEpic = (action$, state$) => action$.pipe(
  ofType(mainBet.add, twentyOne.add, perfectPair.add, perfectEleven.add, tenTwenty.add),
  filter(() => {
    const roundData = roundSelector(state$.value);
    return roundData?.canPlaceBets && !roundData?.isDealNow;
  }),
  pluck('payload'),
  map((bets) => values(bets).map((betType) => {
    const type = head(keys(betType));
    return validateBets(betType, state$.value, type);
  })),
  flatMap((args) => {
    const actions = args.map(({ bets, valid, ...result }) => {
      const res = [ ...(valid && bets && !isEmpty(bets) ? [ bet.set(bets), ] : []), ];
      res.push(...result.actions);
      return res;
    });
    return concat(...actions);
  })
);

const betAllSidebetsEpic = (action$) => action$.pipe(
  ofType(betBJ.allSidebets),
  pluck('payload'),
  concatMap(({ seat, value, enabledSidebets, }) => {
    const sidebetsActions = Object.entries(enabledSidebets)
      .map(([ key, sidebet, ]) => sidebet.add({
        [seat]: {
          [key]: {
            index: seat, value,
          },
        },
      }));
    return of(...sidebetsActions);
  })
);

// const lastBetsTimeEpic = (action$) => action$.pipe(
//   ofType(round.set),
//   pluck('payload'),
//   filter(({ roundStatus, }) => equals(roundStatus, LAST_BETS)),
//   flatMap(() => of(soundActions.sound.play({ soundName: 'last_bets', spriteName: 'bj_sprite', }))),
// );

const betResetEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  pluck('payload'),
  filter(({ roundStatus, }) => roundStatus === PLACE_YOUR_BETS || roundStatus === GAME_RESULT),
  flatMap(({ roundStatus, }) => {
    const isDealNow = isDealNowSelector(state$.value);
    if (roundStatus === PLACE_YOUR_BETS && isDealNow) {
      return EMPTY;
    }
    return concat(
      of(history.reset()),
      of(bet.reset()),
      of(playerTurn.set({
        noMoreDecisions: false,
      }))
    );
  })
);

const betUndoEpic = (action$, state$) => action$.pipe(
  ofType(history.pop),
  filter(() => {
    const roundData = roundSelector(state$.value);
    return roundData?.canPlaceBets && !roundData?.isDealNow;
  }),
  map(() => validateBetUndo(null, state$.value)),
  flatMap((result) => of(...result.actions)),
);

const rebetEpic = (action$, state$) => action$.pipe(
  ofType(bet.rebet),
  filter(() => {
    const roundData = roundSelector(state$.value);
    return roundData?.canPlaceBets && !roundData?.isDealNow;
  }),
  map(() => validateRebet(null, state$.value)),
  flatMap((result) => of(...result.actions)),
);

const rebetNextRoundEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  pluck('payload', 'roundStatus'),
  filter(allPass([ equals(PLACE_YOUR_BETS), () => rebetNextRoundSelector(state$.value), ])),
  map(() => validateRebetNextRound(state$.value)),
  flatMap((result) => of(...result)),
);

const doubleEpic = (action$, state$) => action$.pipe(
  ofType(bet.double),
  filter(() => {
    const roundData = roundSelector(state$.value);
    return roundData?.canPlaceBets && !roundData?.isDealNow;
  }),
  map(() => validateDouble(state$.value)),
  flatMap((result) => of(...result.actions)),
);

const betOnAllEpic = (action$, state$) => action$.pipe(
  ofType(betOnAll),
  filter(() => {
    const roundData = roundSelector(state$.value);
    return roundData?.canPlaceBets && !roundData?.isDealNow;
  }),
  map(() => validateBetOnAll(state$.value)),
  flatMap((result) => of(...result.actions)),
);

const betRequestEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  pluck('payload', 'roundStatus'),
  distinctUntilChanged(),
  filter(equals(NO_MORE_BETS)),
  skipUntil(
    action$.pipe(
      ofType(round.set),
      pluck('payload', 'roundStatus'),
      distinctUntilChanged(),
      filter(equals(PLACE_YOUR_BETS)),
      take(1)
    )
  ),
  filter(() => {
    const { canSendNormalBetRequest, } = roundSelector(state$.value);
    return canSendNormalBetRequest;
  }),
  map(() => createBetRequestPayload(state$.value)),
  filter(length),
  flatMap((_actions) => concat(
    ...rMap(of, _actions),
    of(betActions.bet.success()),
    of(round.set({ canSendNormalBetRequest: false, }))
  )),
);
const getTimerLeftWithDelay = (state, timer) => Math.floor(timer - videoDelaySelector(state));

const callBetsEpic = (action$, state$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  filter(({ MessageType, }) => equals(MessageType, CALL_BETS)),
  flatMap(({
    SeatId,
    CallBets,
    ClientId,
    TimerTimeLeft,
    RoundTripStartTime,
  }) => {
    const state = state$.value;
    const seat = nonDealerSeatsSelector(state).find((seat) => seat.seatId === SeatId);
    const autoStandEnabled = enableAutoStandSelector(state);
    const autoStandValue = autoStandSelector(state);
    const totalCardValue = +[ ...seat.cards, ].pop().totalCardValues;

    const playerSetPayload = {
      callBets: {
        clientId: ClientId,
        seatId: SeatId,
        decisions: CallBets,
      },
      playerTurn: {
        clientId: ClientId,
        seatId: SeatId,
        playerId: seat?.playerId,
        nickname: seat?.nickname,
      },
    };

    if (seat.earlyDecision) {
      return concat(
        of(players.set(playerSetPayload)),
        of(decision.request({ type: seat.earlyDecision, seatId: SeatId, isEarlyDecision: seat.earlyDecision, }))
      );
    }

    if (
      !CallBets.includes(SPLIT)
      && autoStandEnabled
      && autoStandValue
      && !Number.isNaN(totalCardValue) && totalCardValue >= autoStandValue
    ) {
      return concat(
        of(players.set(playerSetPayload)),
        of(decision.request({ type: STAND, seatId: SeatId, }))
      );
    }

    return concat(
      of(
        round.set({
          roundStatus: CALL_BETS,
          timeLeft: getTimerLeftWithDelay(state, TimerTimeLeft),
          totalTime: TimerTimeLeft,
          roundTime: RoundTripStartTime,
          playerDecision: !seat.earlyDecision,
        })
      ),
      of(
        players.set(playerSetPayload)
      )
    );
  }),
);

const callBetDecisionEpic = (action$, store$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  filter(({ MessageType, }) => equals(MessageType, CALL_BET_DECISION)),
  flatMap(({
    ClientId, SeatId, CallBetDecision,
  }) => {
    const state = store$.value;
    const splitSeatIndex = `${SeatId}-2`;
    const seat = nonDealerSeatsSelector(state).find((s) => s.seatId === SeatId);
    const splitSeat = nonDealerSeatsSelector(state).find((s) => s.seatId === splitSeatIndex);

    const actions = [
      players.set({
        callBets: {},
        playerTurn: {
          clientId: ClientId,
          seatId: SeatId,
          playerId: seat?.playerId,
          nickname: seat?.nickname,
        },
      }),
      seats.update({
        seatId: SeatId,
        callBetDecision: CallBetDecision,
      }),
    ];

    // UBJ PLAY ONE HAND
    if (isUBJSelector(state) && CallBetDecision === PLAYER_CALLED_DO_NOT_SPLIT) {
      actions.push(
        seats.update({ // move split seat relevant cards to extra cards list
          seatId: splitSeatIndex,
          taken: false,
          betsList: {},
          [CARD_TYPES_MAP.Relevant]: [],
          [CARD_TYPES_MAP.Extra]: [ ...splitSeat[CARD_TYPES_MAP.Relevant], ...splitSeat[CARD_TYPES_MAP.Extra], ],
        }),
        players.set({
          callBets: {
            clientId: ClientId,
            seatId: SeatId,
            decisions: [],
          },
        }),
      );
    }

    // UBJ PLAY TWO HANDS
    if (isUBJSelector(state) && CallBetDecision === PLAYER_CALLED_SPLIT) {
      const defaultEmptyMainBet = { main: { index: 's1', value: 0, valid: false, }, };
      const currentBets = currentBetsSelector(state);
      const { main, } = propOr(defaultEmptyMainBet, SeatId, currentBets);

      actions.push(
        seats.update({ // duplicate bets to split seat and move extra cards to relevant cards list
          seatId: splitSeatIndex,
          taken: true,
          betsList: {
            main: {
              ...(seat.betsList.main || {}),
              index: splitSeatIndex,
            },
          },
          [CARD_TYPES_MAP.Relevant]: [ ...splitSeat[CARD_TYPES_MAP.Extra], ...splitSeat[CARD_TYPES_MAP.Relevant], ],
          [CARD_TYPES_MAP.Extra]: [],
        }),
        bet.set({ [splitSeatIndex]: { main: { ...main, index: splitSeatIndex, }, }, }),
        players.set({
          callBets: {
            clientId: ClientId,
            seatId: SeatId,
            decisions: [],
          },
          userSeats: [ SeatId, splitSeatIndex, ],
        }),
      );
    }

    // BJ/ABJ CLASSIC SPLIT
    if (!isUBJSelector(state) && CallBetDecision === PLAYER_CALLED_SPLIT) {
      const firstCard = head(seat.cards);
      const lastCard = last(seat.cards);
      const isSeatOwner = seat.playerId === ClientId;
      const splitSeatIndex = `${SeatId}-2`;
      // updates the split seat bet list with correct index
      const splitSeatData = {
        ...seat,
        betsList: {
          main: {
            ...seat.betsList.main,
            index: splitSeatIndex,
          },
        },
      };

      const cardValue = isAce(prop('cardName', lastCard)) ? '1/11' : divide(prop('totalCardValues', lastCard), 2);
      const splitHandFirstCard = assocPath([ 'totalCardValues', ], cardValue, firstCard);
      const splitHandSecondCard = assocPath([ 'totalCardValues', ], cardValue, lastCard);

      const firstSeatCard = [ splitHandFirstCard, ];
      const secondSeatCard = [ splitHandSecondCard, ];

      if (isSeatOwner) {
        // Place bet for split seat only main bets
        const currentSeatBets = currentBetsSelector(state)[SeatId];
        if (currentSeatBets) {
          const { main, } = currentSeatBets;
          actions.push(bet.set({ [splitSeatIndex]: { main: { ...main, index: splitSeatIndex, }, }, }));
        }
      }

      actions.push(...[
        seats.concat({
          ...splitSeatData,
          seatId: splitSeatIndex,
          cards: secondSeatCard,
          callBetDecision: CallBetDecision,
        }),
        seats.update({
          ...seat,
          callBetDecision: CallBetDecision,
          seatId: SeatId,
          cards: firstSeatCard,
        }),
      ]);
    }

    if (CallBetDecision === PLAYER_CALLED_DOUBLE) {
      const allSeats = nonDealerSeatsSelector(state);
      const currentSeat = find(propEq('seatId', SeatId))(allSeats);
      const betValue = lensPath([ 'betsList', 'main', 'value', ]);
      const seatUpdate = set(betValue, view(betValue, currentSeat) * 2, currentSeat);
      if (currentSeat.playerId !== ClientId) {
        actions.push(seats.update({
          ...seatUpdate,
          callBetDecision: CallBetDecision,
        }));
      }
      if (currentSeat.playerId === ClientId) {
        const current = currentBetsSelector(state);
        const mainBet = pathOr({}, [ SeatId, 'main', ], current);
        actions.push(
          bet.set({ [SeatId]: { main: mainBet, }, }),
          seats.update({
            seatId: SeatId,
            betsList: seatUpdate?.betsList || currentSeat?.betsList,
          })
        );
      }
    }

    if (CallBetDecision === PLAYER_CALLED_STAND) {
      actions.push(
        seats.normalizeScores({
          playerSeat: {
            seatId: SeatId,
          },
        }),
      );
    }

    actions.push(
      round.set({ roundStatus: NO_MORE_BETS, })
    );

    return of(...actions);
  }),
);

export default combineEpics(
  betUndoEpic,
  betResetEpic,
  betEpic,
  betAllSidebetsEpic,
  betRequestEpic,
  rebetEpic,
  doubleEpic,
  betOnAllEpic,
  callBetsEpic,
  callBetDecisionEpic,
  rebetNextRoundEpic
);
