import { of, } from 'rxjs';
import { isNil, propOr, isEmpty, } from 'ramda';

import { actions as bootstrapActions, selectors as bootstrapSelectors, } from '@ezugi/bootstrap';
import { GAME_RESULT, } from '@ezugi/constants';
import playersActions from '../../actions/players';
import { DEALER_SEAT_ID, } from '../seats/constants';

import { gameResultsSelector, isWinnerSelector, totalLastWin, } from '../../selectors';

const {
  gameIdSelector,
} = bootstrapSelectors;

const {
  roundActions: { round, },
  gameActions: { game, },
} = bootstrapActions;

const { seats, } = playersActions;

// used only for UBJ, because on auto-split if you play one hand you will get empty object for the other seat
const getUBJGameResultsSeats = (GameResults) => (
  GameResults.filter((seat) => !isEmpty(seat) && [ 's1', 's1-2', ].includes(seat.SeatId))
);
const computeWinAmount = (GameResults) => (
  getUBJGameResultsSeats(GameResults).reduce((acc, seat) => acc + propOr(0, 'WinAmount', seat), 0)
);

/* GAME */
const mapBJGameUpdate = (_, state) => ([
  game.set({
    /**
       * Update lastWin with all the winning from all of the player's seats
       */
    gameResults: {
      ...gameResultsSelector(state),
      lastWin: totalLastWin(state),
    },
    lastWin: totalLastWin(state),
  }),
]);

const mapUBJGameUpdate = ({ GameResults, }) => {
  const lastWin = computeWinAmount(GameResults);
  return [
    game.set({
      /**
       * Update lastWin with all the winning from all of the player's seats
       */
      gameResults: {
        gameResults: GameResults,
        lastWin, // TODO: why do we still need it here?
      },
      lastWin,
    }),
  ];
};

/* SEATS */
const mapBJSeatsUpdate = ({ GameResults, }) => ([
  seats.normalizeScores({
    playerSeat: {
      seatId: DEALER_SEAT_ID,
    },
  }),
  seats.status(GameResults.PlayerCards.reduce((acc, { SeatId, SeatResult, }) => ({
    ...acc,
    [SeatId]: SeatResult,
  }), {})),
]);

const mapUBJSeatsUpdate = ({ GameResults, }) => ([
  seats.normalizeScores({
    playerSeat: {
      seatId: DEALER_SEAT_ID,
    },
  }),
  seats.status(GameResults.reduce((acc, { SeatId, SeatResult, }) => ({
    ...acc,
    [SeatId]: SeatResult,
  }), {})),
]);

/* ROUND */
const mapBJRoundUpdate = ({ LastWinners, IsPlayingFreeRound, }, state) => ([
  round.set({
    isWinner: isWinnerSelector(state),
    roundStatus: GAME_RESULT,
    winAmount: totalLastWin(state),
    winnerList: LastWinners,
    lastWin: totalLastWin(state),
    isPlayingFreeRound: IsPlayingFreeRound,
  }),
]);

const mapUBJRoundUpdate = ({ GameResults, LastWinners, }) => {
  const isWinner = getUBJGameResultsSeats(GameResults).find((seat) => propOr(false, 'SeatResult', seat)) || false;
  const winAmount = computeWinAmount(GameResults);
  return [
    round.set({
      roundStatus: GAME_RESULT,
      isWinner,
      winAmount,
      winnerList: LastWinners,
      lastWin: winAmount,
    }),
  ];
};

const getUpdateFunctions = (gameTypeId) => {
  if (isNil(gameTypeId)) return [ mapBJGameUpdate, mapBJSeatsUpdate, mapBJRoundUpdate, ];

  switch (gameTypeId) {
  // BJ
  case '1':
    return [ mapBJRoundUpdate, mapBJSeatsUpdate, mapBJGameUpdate, ];
  // ABJ
  case '10':
    return [ mapBJRoundUpdate, mapBJSeatsUpdate, mapBJGameUpdate, ];
  // UBJ
  case '12':
    return [ mapUBJRoundUpdate, mapUBJSeatsUpdate, mapUBJGameUpdate, ];

  default:
    return [ mapBJRoundUpdate, mapBJSeatsUpdate, mapBJGameUpdate, ];
  }
};


export default function handleGameResult(socketMessage, state) {
  // const { roundId, timeStamp, WinAmount, GameResults, LastWinners, } = socketMessage;
  const [ mapGameUpdate, mapSeatsUpdate, mapRoundUpdate, ] = getUpdateFunctions(gameIdSelector(state));

  const actions = [
    ...mapGameUpdate(socketMessage, state),
    ...mapSeatsUpdate(socketMessage, state),
    ...mapRoundUpdate(socketMessage, state),
  ];

  return of(...actions);
}
