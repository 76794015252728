/* eslint-disable no-mixed-operators */
import {
  flatMap, pluck, map, distinctUntilChanged, filter,
} from 'rxjs/operators';
import { ofType, combineEpics, } from 'redux-observable';
import { of, concat, } from 'rxjs';
import { actions, } from '@ezugi/bootstrap';
import {
  equals, length, filter as rFilter, last, prop,
} from 'ramda';
import decisionActions from '../../actions/decision';
import playersActions from '../../actions/players';
import {
  playerSeatsWithCardsSelector,
  authSelector,
  gameSelector,
  userSelector,
  playersSelector,
  roundSelector,
  dealerSeatSelector,
  noMoreDecisionsSelector,
} from '../../selectors';
import {
  DOUBLE, PLAYER_CALLED_DOUBLE, PLAYER_CALLED_STAND, STAND, PLAYER_CALLED_SPLIT,
} from '../../constants/decision';
import { ROUND_TOTAL, } from '../../../components/SeatResult/constants';
import { SURRENDER, } from '../../../components/Insurance/constants';

const { seats, playerTurn, } = playersActions;
const { BUST, BLACKJACK, } = ROUND_TOTAL;
const {
  roundActions: { round, },
} = actions;
const { decision, } = decisionActions;

const buildDecisionPayload = (state, decisionCall) => {
  const { operatorId, table_id: tableId, } = authSelector(state);
  const { uid, nickname, currentPlayerToken, } = userSelector(state);
  const { serverName, } = gameSelector(state);
  const {
    callBets: { seatId, },
  } = playersSelector(state);

  const { roundId, } = roundSelector(state);

  return {
    ClientId: `${operatorId}|${uid}|${tableId}`,
    MessageType: 'BettingCall',
    Nickname: nickname,
    destination: 'table',
    gameType: serverName,
    TableId: tableId,
    SeatId: seatId,
    BettingCallsMessageType: decisionCall,
    roundId,
    CurrentPlayerToken: currentPlayerToken,
  };
};

const checkNoDecisions = (state) => {
  const seatsWithCards = playerSeatsWithCardsSelector(state);
  const dealerSeat = dealerSeatSelector(state);
  // todo this should be refactored to be readable and maintainable
  const seatsWithDecisions = rFilter(
    ({ callBetDecision, totalResult, cards, insuranceDecision, earlyDecision, }) => (
      callBetDecision === STAND
      || callBetDecision === DOUBLE
      || callBetDecision === PLAYER_CALLED_STAND
      || callBetDecision === PLAYER_CALLED_DOUBLE
      || (callBetDecision === PLAYER_CALLED_SPLIT && prop('cardValue', cards[0]) === '11')
      || earlyDecision === STAND
      || earlyDecision === DOUBLE
      || earlyDecision === PLAYER_CALLED_STAND
      || earlyDecision === PLAYER_CALLED_DOUBLE
      || (earlyDecision === PLAYER_CALLED_SPLIT && prop('cardValue', cards[0]) === '11')
      || totalResult === BUST
      || insuranceDecision === SURRENDER
      || prop('totalCardValues', last(cards)) >= 21
      || prop('totalResult', last(cards)) === BLACKJACK
    ), seatsWithCards
  );

  const noMoreDecisions = (!!length(seatsWithCards) && equals(length(seatsWithDecisions), length(seatsWithCards)))
    || prop('totalCardValues', last(dealerSeat.cards)) === 'BJ';

  const out = [];
  if (noMoreDecisionsSelector(state) !== noMoreDecisions) {
    out.push(playerTurn.set({
      noMoreDecisions,
    }));
  }

  return out;
};

/* eslint-disable camelcase */
const decisionRequestEpic = (action$, state$) => action$.pipe(
  ofType(decision.request),
  pluck('payload'),
  map(({ type, }) => decision.send(buildDecisionPayload(state$.value, type)))
);

const resetEarlyEpic = (action$, state$) => action$.pipe(
  ofType(decision.send),
  pluck('payload', 'type'),
  flatMap(() => {
    const {
      callBets: { seatId, },
    } = playersSelector(state$.value);

    return concat(
      of(round.set({ playerDecision: false, })),
      of(decision.early({ seatId, earlyDecision: null, }))
    );
  })
);

const noMoreDecisionsEpic = ($action, $state) => $action.pipe(
  ofType(seats.set, seats.add, seats.update, seats.normalizeScores, seats.status, decision.early),
  pluck('payload'),
  filter(({ earlyDecision, }) => (earlyDecision !== null)),
  distinctUntilChanged(equals),
  flatMap(() => of(...checkNoDecisions($state.value)))
);

export default combineEpics(decisionRequestEpic, resetEarlyEpic, noMoreDecisionsEpic);
