import {
  path, pathOr, propOr, prop,
} from 'ramda';
import { createSelector, } from 'reselect';
import { anteRangesSelector, } from './table';
import { isUBJSelector, } from './game';

export const configSelector = propOr({}, 'config');

export const agoraConfigSelector = path([ 'config', 'agora', ]);
export const definitionsSelector = path([ 'config', 'definitions', ]);
export const widgetsSelector = path([ 'config', 'widgets', ]);
export const preloaderSelector = path([ 'config', 'preloader', ]);
export const generalSelector = path([ 'config', 'general', ]);
export const skinSelector = path([ 'config', 'skin', ]);
export const formatSelector = path([ 'config', 'format', ]);
export const authSelector = path([ 'config', 'authParams', ]);
export const nameBeforeGreetingSelector = pathOr(false, [ 'config', 'general', 'nameBeforeGreetingMessage', ]);
export const playerHistorySelector = propOr({}, 'history');
export const gameTypesSelector = createSelector(configSelector, prop('gameTypes'));
export const chatConfigSelector = createSelector(configSelector, prop('chat'));
export const bjConfigSelector = createSelector(configSelector, prop('bj'));
export const boVideoConfigSelector = createSelector(bjConfigSelector, propOr({}, 'videoConfig'));
export const allowDealNowSelector = createSelector(
  bjConfigSelector,
  isUBJSelector,
  (bjConfig, isUBJ) => !isUBJ && bjConfig?.dealNow
);
export const actionButtonsCustomColorsSelector = createSelector(bjConfigSelector, prop('actionButtonsCustomColors'));
export const emptySeatsCustomColorsSelector = createSelector(bjConfigSelector, prop('emptySeatsCustomColors'));
export const disableSideBetsSelector = createSelector(bjConfigSelector, prop('disableSideBets'));
export const bjSatellitesSelector = createSelector(
  bjConfigSelector,
  ({ seatsSattelitesPositioning, }) => seatsSattelitesPositioning || {},
);
export const bjSeatsSelector = createSelector(
  bjConfigSelector,
  ({ seatsPositioning, }) => seatsPositioning || {},
);

export const languagesSelector = createSelector(
  generalSelector,
  prop('availableLanguages')
);
export const currentLanguageSelector = createSelector(
  generalSelector,
  languagesSelector,
  (generalConfig, languages) => languages.find(({ code, }) => code === generalConfig.language)
    || languages.find(({ code, }) => code === 'en')
);
export const showDealerNameInTipsSelector = createSelector(
  widgetsSelector,
  propOr(false, 'tipsShowDealerName')
);
export const enableAnteSelector = createSelector(bjConfigSelector, propOr(false, 'enableAnte'));

export const anteConfigObjSelector = createSelector(
  enableAnteSelector,
  anteRangesSelector,
  (isAnteBetEnabled, anteRanges) => ({
    isAnteBetEnabled,
    anteRanges,
  })
);

export const perfectElevenConfigSelector = createSelector(
  bjConfigSelector,
  propOr(false, 'perfect11Bonus')
);

export const tenTwentyConfigSelector = createSelector(
  bjConfigSelector,
  propOr(false, 'ten20Bonus')
);

export const enableAutoStandSelector = createSelector(
  bjConfigSelector,
  ({ autoStand = false, } = {}) => autoStand
);

export const rebetNextRoundConfigSelector = createSelector(
  bjConfigSelector,
  ({ rebetNextRoundConfig, }) => (rebetNextRoundConfig && rebetNextRoundConfig.isRebetNextRound)
);
