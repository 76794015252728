import { handleActions, } from 'redux-actions';
import {
  mergeWith, omit, dissoc, head, propOr, isEmpty,
} from 'ramda';
import { actions, } from '@ezugi/bootstrap';

import betsActions from '../actions/bets';
import { getTotalBet, } from '../epics/bets/utils';
import { isNotEmpty, } from '../../util';
import { ANTE_BET, } from '../../constants/betTypes';

const { betActions: { bet, history, totalBet, }, } = actions;
const { bet: localBet, rebetNextRound, } = betsActions;

const addValues = (a, b) => ({ ...a, ...b, value: +(a.value + b.value).toFixed(2), });

const extractAnteBet = (seat) => {
  const seatId = head(Object.keys(seat));

  // extract only ante bet from seat's bets
  const seatBetWithAnte = propOr({}, ANTE_BET, head(Object.values(seat)));
  // remove ante bet from seat's bets
  const seatBetWithoutAnte = dissoc(ANTE_BET, head(Object.values(seat)));
  return {
    seatAnteBet: !isEmpty(seatBetWithAnte) ? { [seatId]: { [ANTE_BET]: seatBetWithAnte, }, } : {},
    seatWithoutAnte: { [seatId]: seatBetWithoutAnte, },
  };
};

export const INITIAL_STATE = {
  current: {},
  totalBet: 0,
};

INITIAL_STATE.history = [ { ...INITIAL_STATE, }, ];

export default handleActions(
  {
    // eslint-disable-next-line no-shadow
    [bet.init]: (state, { payload, }) => ({
      ...state,
      current: payload,
      totalBet: getTotalBet(payload),
    }),
    // eslint-disable-next-line no-shadow
    [bet.set]: ({ history, ...state }, { payload, }) => {
      const { seatAnteBet, seatWithoutAnte, } = extractAnteBet(payload);
      const seatWithMergedBets = mergeWith(mergeWith(addValues), state.current, seatWithoutAnte);
      // re-add ANTE bet from payload for TotalBet calculus
      const current = isEmpty(seatAnteBet)
        ? seatWithMergedBets
        : mergeWith((a, b) => ({ ...a, ...b, }), seatWithMergedBets, seatAnteBet);
      const currTotalBet = getTotalBet(current);
      return {
        ...state,
        current,
        totalBet: currTotalBet,
        history: [ ...history, { ...state, current, totalBet: currTotalBet, }, ],
      };
    },
    [bet.reset]: (state) => ({
      ...state,
      ...INITIAL_STATE,
    }),
    [bet.clear]: (state) => ({
      ...state,
      ...INITIAL_STATE,
      history: [ ...state.history, INITIAL_STATE, ],
    }),
    [bet.cache]: (state, { payload, }) => ({
      ...state,
      last: payload,
    }),
    [bet.apply]: (state, { payload, }) => {
      const currTotalBet = getTotalBet(payload.current ? payload.current : state.current);

      return {
        ...state,
        ...payload,
        totalBet: currTotalBet,
        history: [ ...state.history, { ...payload, totalBet: currTotalBet, }, ],
      };
    },
    [localBet.remove]: (state, { payload, }) => ({
      ...state,
      current: omit(payload, state.current),
      totalBet: getTotalBet(omit(payload, state.current)),
    }),
    [history.reset]: (state) => ({
      ...state,
      history: [ { ...INITIAL_STATE, rebet: isNotEmpty(state.last), }, ],
    }),
    [history.apply]: (state, { payload, }) => ({
      ...state,
      ...payload,
    }),
    [totalBet.set]: (state, { payload: { value, }, }) => ({
      ...state,
      totalBet: value,
    }),
    [rebetNextRound.set]: (state, { payload, }) => ({
      ...state,
      rebetNextRound: payload,
    }),
  },
  INITIAL_STATE
);
