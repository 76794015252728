import React, { useCallback, useMemo, } from 'react';
import { cx, } from 'react-emotion';
import bowser from 'bowser';
import { selectors, useIntl, useAppState, } from '@ezugi/bootstrap';
import { Button, } from '@ezugi/primitives';

import { MAIN_BET, } from '../../constants/betTypes';
import dealNowActions from '../../store/actions/dealNow';
import {
  playerSeatsIdsSelector,
  isDealNowSelector,
  allowDealNowSelector,
  currentBetsSelector,
} from '../../store/selectors';
import CheckIcon from './check-icon.svg';
import * as styles from './style';


const isHandheld = bowser.mobile || bowser.tablet;
const spec = (state) => ({
  canPlaceBets: selectors.canPlaceBetsSelector(state),
  mySeatIds: playerSeatsIdsSelector(state),
  enabled: allowDealNowSelector(state),
  isDealNow: isDealNowSelector(state),
  currentBets: currentBetsSelector(state),
});
const intlSpec = {
  keys: {
    dealNowLabel: 'games_ui.deal_now',
    waitingForVoteLabel: 'games_ui.waiting_for_other_players_to_vote',
  },
};

export default function DealNowButton() {
  const [ { isDealNow, canPlaceBets, mySeatIds, enabled, currentBets, }, dispatch, ] = useAppState(spec);
  const { dealNowLabel, waitingForVoteLabel, } = useIntl(intlSpec);
  const hasValidBets = useMemo(() => currentBets && Object.values(currentBets).find((bet) => bet[MAIN_BET]?.valid),
    [ currentBets, ]);
  const onClick = useCallback(() => {
    if (!isDealNow && enabled) {
      dispatch(dealNowActions.dealNow.request());
    }
  }, [ isDealNow, enabled, dispatch, ]);

  return (
    enabled && mySeatIds.length > 0 && hasValidBets
      ? (
        <Button
          flex={true}
          uppercase={true}
          label={!isDealNow ? dealNowLabel : waitingForVoteLabel}
          data-e2e="bet-toolbar-deal-now"
          icon={isDealNow ? CheckIcon : undefined}
          className={cx(styles.dealNowButton, {
            [styles.withLargeText]: !isDealNow,
            [styles.handheld]: isHandheld,
            [styles.ghost]: isDealNow,
            [styles.withIcon]: isDealNow,
            [styles.hidden]: !canPlaceBets && !isDealNow,
            [styles.withYOffset]: isDealNow && !isHandheld,
          })}
          onClick={onClick}
        />
      ) : null
  );
}
