import styled, { css, keyframes, } from 'react-emotion';
import { keys, pathOr, length, } from 'ramda';
import { fadeIn, } from '../../util/animations';
import { betsMap, } from './constants';
import { MAIN_BET, } from '../../constants/betTypes';
import { MAIN_BET_ID, } from '../GameBets/constants';

export const feltContainerCSS = css`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: flex-end;
  height: 67.25vw;
  max-height: 40vh;
  width: 100%;
  align-items: center;
  perspective: 800px;
`;

export const videoContainerUBJ = css`
  @media all and (orientation: portrait) {
    > div:first-child {
      padding-bottom: 44.25%;
    }
  }
`;

export const containerMobileCSS = css`
  position: static;
  justify-content: center;
  @media all and (orientation: portrait) { 
    max-height: calc(var(--innerHeight) - max(67.25vw, 40vh) - 80px);
    margin-bottom: unset;
  }

  @media all and (orientation: landscape) { 
    margin-bottom: 30px;
  }
`;

export const containerCSS = css`
  height: 100%;
  padding: 0;
  animation: ${fadeIn} .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  pointer-events: all;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 130px;

  svg {
    overflow: visible;
    align-items: stretch;
  }

  div {
    display: flex;
  }
`;

const transitionEffect = 'transition: .5s ease-in-out;';

const scaleKeyframe = keyframes`
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
`;

const slideUpKeyframe = keyframes`
  from {
    transform: translateY(-5px);
  }
  to {
    transform: translateY(0px);
  }
`;

export const perfectElevenMobileCSS = css`
  transform: translate(-88px, 0px);
`;

export const tenTwentyMobileCSS = css`
  transform: translate(88px, 0px);
`;

export const perfectPairMobileCSS = css`
  transform: translate(-46px, -76px);
`;

export const twentyOneMobileCSS = css`
  transform: translate(46px, -76px);
`;

export const outerSidebetsDesktopCSS = css`
  transform: translateY(-24px);
`;

export const innerSidebetsDesktopCSS = css`
  transform: translateY(-5px);
`;

export const mainBetCSS = css`
  fill: #000000;
`;

export const desktopSvgLineStyle = css`
  transform: scale(1.5) translateY(80px) rotateX(52deg);
`;

export const mobileSvgLineStyle = css`
  @media all and (orientation: landscape) {
    transform: rotateX(48deg) translateY(calc(var(--video-width) / 2.8)) scale(0.9);
    max-width: calc(var(--video-width) / 2);
  }
  @media all and (orientation: portrait) {
    transform: translateY(calc(var(--innerHeight) / 10)) scale(1);
  }
`;

export const mobileSvgBlockStyle = css`
  transform: scale(1.3);
  @media all and (orientation: landscape) {
    box-sizing: content-box;
    padding: 0px 20px;
    max-width: 100vh;
  }
  @media all and (orientation: portrait) {
    padding-top: 48px;
  }
`;

export const feltStyle = css`
  @media (orientation: portrait) {
    height: auto;
    width: 40vw;
    transform: rotateX(48deg);
  }
`;

export const SVG = styled.svg`
  ${transitionEffect}
  overflow: hidden;
  pointer-events: all;
  height: auto;
  @media (orientation: portrait) {
    max-width: 94vw;
  }

  mask {
    mask-type: alpha;
  }
  g {
    ${transitionEffect}
  }
  ${({ canPlaceBets, bets, seatId, felt, }) => {
    if (canPlaceBets && pathOr(false, [ seatId, MAIN_BET, 'valid', ], bets)) { return ''; }
    const filterVal = felt ? 1 : 0.5;
    const validSideBetsIds = keys(pathOr([], [ seatId, ], bets))
      .map((bet) => `#${betsMap[bet]}`).join(',');
    return `g[id]:not(${length(validSideBetsIds) ? validSideBetsIds : `#${MAIN_BET_ID}`}) {
      filter: grayscale(${filterVal});
      opacity: 0.5;
      circle {
        fill: #222543;
        stroke: rgba(255, 255, 255, .3);
      }
    }`;
  }}
`;

export const sideBetInnerClassName = css`
  fill: #000;
`;

export const sideBetBorderClassName = css`
  stroke: rgba(255, 255, 255, .3);
`;

export const sideBetPathClassName = css`
  fill: #000;
  stroke: rgba(255, 255, 255, 1);
`;

export const sideBetPathFeltCSS = css`
  fill: transparent;
  stroke: rgba(255, 255, 255, 0.6);
`;

export const mainBetFeltCSS = css`
  ${sideBetPathFeltCSS}
`;

export const ChipContainer = styled.g`
  animation: ${scaleKeyframe} 0.3s forwards ease-out;
  transform-origin: top left;
`;

export const GroupContainer = styled.g`
  transform-origin: center;
  animation: ${slideUpKeyframe} 0.3s forwards cubic-bezier(0.46, 0.03, 0.52, 0.96);
  animation-iteration-count: 9;
  animation-direction: alternate;
  text {
    fill: #000;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: -0.03em;
  }
`;
