/* eslint-disable no-shadow */
import { of, } from 'rxjs';
import {
  first, filter, flatMap, switchMap, map, pluck,
} from 'rxjs/operators';
import { combineEpics, ofType, } from 'redux-observable';
import { actions as bootstrapActions, selectors as bootstrapSelectors, state$, } from '@ezugi/bootstrap';

import dealNowActions from '../../actions/dealNow';
import { playerSeatsSelector, isUBJSelector, } from '../../selectors';
import { createBetRequestPayload, buildParams, } from '../bets/utils';
import { isUBJonURL, } from '../socket/utils';

const { dealNow, } = dealNowActions;

const {
  roundActions: { round, },
  socketActions: { socket, },
  uiActions: { ui, },
} = bootstrapActions;

const { uiSelector, } = bootstrapSelectors;

const dealNowRequestEpic = (action$, state$) => action$.pipe(
  ofType(dealNow.request),
  switchMap(() => state$.pipe(first())),
  filter((state) => !isUBJonURL && !isUBJSelector(state)),
  flatMap((state) => {
    const { seats, } = playerSeatsSelector(state);

    const betRequestActions = createBetRequestPayload(state);
    const placeBetActionIndex = betRequestActions.findIndex(({ payload, }) => payload.MessageType === 'PlaceBet');

    const placeBetAction = betRequestActions[placeBetActionIndex] ?? { payload: buildParams(undefined, state), };
    placeBetAction.payload.MessageType = 'DealNow';
    placeBetAction.payload.SeatIds = seats.map(({ seatId, }) => seatId);
    placeBetAction.type = `${socket.send}`;
    betRequestActions.splice(placeBetActionIndex, 1);

    return of(
      placeBetAction,
      ...betRequestActions,
      round.set({ canSendNormalBetRequest: false, isDealNow: true, }),
    );
  }),
);

const resetDealNowEpic = (action$) => action$.pipe(
  ofType(round.set),
  pluck('payload'),
  filter(({ isDealNow, }) => typeof isDealNow === 'boolean'),
  switchMap(({ isDealNow, }) => state$.pipe(first(), map((state) => ({ uiState: uiSelector(state), isDealNow, })))),
  flatMap(({ uiState, isDealNow, }) => of(
    ui.set({ chipSelector: {
      ...uiState?.chipSelector,
      hidden: isDealNow,
    },
    betToolbar: {
      ...uiState?.betToolbar,
      hidden: isDealNow,
    }, }),
  ))
);

export default combineEpics(dealNowRequestEpic, resetDealNowEpic);
