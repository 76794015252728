import {
  MAIN_BET, PERFECT_ELEVEN, PERFECT_PAIR, TEN_TWENTY, TWENTY_ONE,
} from '../../constants/betTypes';
import {
  MAIN_BET_ID, PERFECT_ELEVEN_ID, PERFECT_PAIR_ID, TEN_TWENTY_ID, TWENTY_ONE_ID,
} from '../GameBets/constants';

export const chipsPosMap = {
  [TEN_TWENTY]: { x: 7, y: 45, },
  [TWENTY_ONE]: { x: 87, y: 45, },
  [MAIN_BET]: { x: 170, y: 38, },
  [PERFECT_ELEVEN]: { x: 341, y: 45, },
  [PERFECT_PAIR]: { x: 263, y: 45, },
};
export const betsMap = {
  [MAIN_BET]: MAIN_BET_ID,
  [PERFECT_PAIR]: PERFECT_PAIR_ID,
  [TWENTY_ONE]: TWENTY_ONE_ID,
  [PERFECT_ELEVEN]: PERFECT_ELEVEN_ID,
  [TEN_TWENTY]: TEN_TWENTY_ID,
};
export const CHIP_SIDEBET_SIZE = 46;
export const CHIP_MAINBET_SIZE = 57.5;
export const GAME_VIDEO_BEFORE_BG = 'radial-gradient(50% 30% at 50% min(140vw, 70vh, 70%), #383E72 0%, #1D203A 65.62%)';
export const LABEL_OFFSET_Y = -25;
