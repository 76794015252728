import { css, } from 'react-emotion';

const RGB = '231, 169, 67';

export const dealNowButton = css`
  width: calc(100% - 8px);
  margin: 0 4px;
  background: rgb(${RGB});
  color: #FFF;
  font-weight: bold;
  pointer-events: all;
  opacity: 1;
  transition: background 0.27s linear;
  flex: none;
`;

export const handheld = css`
  width: calc(100% - 12px);
  height: 36px;
  margin: 0 6px 8px;

  @media only screen and (orientation: landscape) {
    display: inline-block;
    position: absolute;
    width: auto;
    margin: auto;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%)
  }
`;

export const ghost = css`
  border: 1px solid #E8A943;
  background: rgba(${RGB}, 0.4);
  pointer-events: none;
`;

export const withIcon = css`
  @media only screen and (orientation: landscape) {
    display: inline-flex;
  }
  div:first-of-type {
    margin-right: 8px
  }
`;

export const withLargeText = css`
  font-size: 14px;
`;

export const hidden = css`
  opacity: 0;
  display: none !important;
  visibility: hidden;
  margin: 0 !important;

   @media only screen and (orientation: landscape) {
    display: none;
  }
`;

export const withYOffset = css`
  margin: 8px 4px 0;
`;
