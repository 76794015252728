/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
import {
  filter, first, flatMap, pluck, switchMap, delay, map as rxMap, tap, debounceTime, distinctUntilChanged, skip,
} from 'rxjs/operators';
import { combineEpics, ofType, } from 'redux-observable';
import {
  of, concat, defer, EMPTY,
} from 'rxjs';
import {
  compose,
  equals,
  find,
  isEmpty,
  last,
  lensProp,
  map,
  omit,
  over,
  propEq,
  symmetricDifference,
  length,
  uniq,
} from 'ramda';
import { actions as bootstrapActions, constants as bootstrapConstants, } from '@ezugi/bootstrap';
import {
  CURRENT_BALANCE,
  GAME_RESULT,
  INITIAL_DATA,
  NO_MORE_BETS,
  PLACE_YOUR_BETS,
  CARD_MESSAGE,
  SPLIT_CARDS,
  LAST_BETS,
  CANCEL_ROUND,
  CANCEL_LAST_CARD,
  VIDEO_MODERATION_RESULT,
  CALL_BETS,
  INSURANCE_CALLS,
} from '@ezugi/constants';

import { GAME_RESULT as GAMERESULTS, WON_SIDE_BETS, } from './constants';

import { getGameParams, } from '../../../util/epicsUtils';
import seatActions from '../../actions/seat';
import betActions from '../../actions/bets';
import decisionActions from '../../actions/decision';
import splitHandActions from '../../actions/splitHand';
import insuranceActions from '../../actions/insurance';

import handleInitialData from './handleInitialData';
import handleGameResult from './handleGameResult';
import handleCardMessage from './handleCardMessage';
import handleCancelLastCardMessage from './handleCancelLastCardMessage';
import handleAutoSplit from './handleAutoSplit';
import handleWonBets from './handleWonBets';

import { pluckBJ, } from '../auth/utils';
import { LEAVE_SEAT, SEAT_TAKEN, } from '../seats/constants';
import {
  activeSeatIdSelector,
  betsHistorySelector,
  currentBetsSelector,
  isDealNowSelector,
  isFreeRoundsEnabledSelector,
  nonDealerSeatsSelector,
  playerSeatsCountSelector,
  playerSeatsIdsSelector,
  playerSeatsSelector,
  roundStatusSelector,
  userCachedSeatsSelector,
  userSeatsIdsSelector,
} from '../../selectors';
import playersActions from '../../actions/players';
import { getTotalBet, } from '../bets/utils';
import { BET_TYPES, START_CALL_BETS, } from '../../../constants';
import { isBJSPonURL, } from './utils';


const {
  socketActions: { socket, },
  roundActions: { round, },
  authActions,
  configActions,
  notificationActions: { notification, },
  soundActions: { sound, },
  errorActions: { error, },
  dialogActions,
} = bootstrapActions;
const { CODES, VIDEO_MODERATION_KICK, VIDEO_MODERATION_BAN, DIALOG, } = bootstrapConstants;

const { insurance, } = insuranceActions;
const { seat, temporaryUnclickable, } = seatActions;
const { bet, history, } = betActions;
const { decision, } = decisionActions;
const { splitHand, } = splitHandActions;
const { seats, userSeats, playerTurn, } = playersActions;
const { MAIN_BET, INSURANCE_BET, } = BET_TYPES;

function initialDataEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === INITIAL_DATA),
    flatMap(handleInitialData),
  );
}

function bjConfigEpic(action$, store$) {
  return action$.pipe(
    ofType(authActions.auth.success),
    switchMap(() => store$.pipe(
      first(),
      pluck('config', 'blackjack'),
    )),
    flatMap((blackjack) => of(
      configActions.config.update({
        bj: pluckBJ(blackjack),
      }),
    )),
  );
}

function socketRequestsEpic(action$) {
  return action$.pipe(
    ofType(seat.request, bet.request, insurance.send, decision.send, splitHand.send),
    pluck('payload'),
    flatMap((params) => of(socket.send(params))),
  );
}

function handleRoundStatusEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ PLACE_YOUR_BETS, NO_MORE_BETS, ].includes(MessageType)),
    flatMap(({ MessageType, allowDealNow, FreeRoundsCount, }) => {
      const state = store$.value;
      const isDealNow = isDealNowSelector(state);
      return concat(of(
        round.set({
          canSendNormalBetRequest: [ PLACE_YOUR_BETS, LAST_BETS, ].includes(MessageType),
          canPlaceBets: [ PLACE_YOUR_BETS, ].includes(MessageType),
          ...(MessageType === NO_MORE_BETS && { isDealNow: false, }),
          ...(MessageType === PLACE_YOUR_BETS && {
            isPlayingFreeRound: false,
            canPlayFreeRound: FreeRoundsCount > 0,
            freeRoundsCount: FreeRoundsCount,
          }),
        }),
        ...(MessageType === PLACE_YOUR_BETS && !isDealNow ? [ seats.clean(), userSeats.clean(), ] : []),
        ...(MessageType === NO_MORE_BETS ? [ userSeats.cache(userSeatsIdsSelector(state)), ] : []),
      ),
      ...(MessageType === NO_MORE_BETS
        ? [ of(sound.stop({ soundName: 'last_bets', })).pipe(delay(200)), ]
        : [ of(configActions.config.update({ bj: { dealNow: allowDealNow, }, },)), ]));
    }),
  );
}

function handleRoundCancelEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CANCEL_ROUND),
    flatMap(() => of(
      round.set({
        canSendNormalBetRequest: false,
        canPlaceBets: false,
        isDealNow: false,
        roundStatus: CANCEL_ROUND,
      }),
      round.set({ roundStatus: NO_MORE_BETS, })
    ),)
  );
}

function handleSeatTakenEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ SEAT_TAKEN, ].includes(MessageType)),
    flatMap((socketMessage) => {
      const state = store$.value;
      const {
        SeatId,
        PlayerOnSeat,
        playerOnSeatId,
        AllowsBetBehind,
      } = socketMessage;

      const isCurrentUserSeat = userSeatsIdsSelector(state).includes(SeatId);
      const currentSeats = [ SeatId, ].concat(playerSeatsIdsSelector(state));
      const cachedUserSeats = userCachedSeatsSelector(state);
      const equalsIgnoreOrder = compose(isEmpty, symmetricDifference);
      const actions = [
        seats.update({
          seatId: SeatId,
          nickname: PlayerOnSeat,
          playerId: playerOnSeatId,
          allowsBetBehind: AllowsBetBehind,
          reservedSeat: false,
        }),
        temporaryUnclickable.reset({ unclickable: false, }),
      ];
      if (isCurrentUserSeat && !equalsIgnoreOrder(cachedUserSeats, currentSeats)) {
        actions.push(userSeats.clean());
      }
      actions.push(userSeats.cache(userSeatsIdsSelector(state)));
      return of(...actions);
    })
  );
}

function handleSeatLeaveEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ LEAVE_SEAT, ].includes(MessageType)),
    flatMap(({ SeatId, }) => {
      /**
       * Remove seat from bets and bets history
       */
      const state = store$.value;
      const currentHistory = betsHistorySelector(state);

      const newHistory = uniq(currentHistory.reduce((acc, { current, totalBet, ...rest }) => {
        const updatedBets = omit([ SeatId, ], current);

        return [
          ...acc,
          {
            ...rest,
            current: updatedBets,
            totalBet: getTotalBet(updatedBets),
          },
        ];
      }, []));
      const isCurrentUserSeat = userSeatsIdsSelector(state).includes(SeatId);
      let actions = [ seats.remove({ seatId: SeatId, }), ];

      if (isCurrentUserSeat) {
        actions = [
          ...actions,
          bet.remove([ SeatId, ]),
          history.apply({ history: newHistory, last: last(newHistory).last, }),
          userSeats.clean(),
        ];
      }

      return of(...actions);
    })
  );
}

function activeSeatUpdateEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ LEAVE_SEAT, SEAT_TAKEN, NO_MORE_BETS, PLACE_YOUR_BETS, ].includes(MessageType)),
    debounceTime(10),
    rxMap(({ SeatId, MessageType, playerOnSeatId, ClientId, }) => {
      if (MessageType === NO_MORE_BETS) {
        return undefined;
      }

      if (MessageType === SEAT_TAKEN) {
        if (playerOnSeatId !== ClientId) return null;

        const roundStatus = roundStatusSelector(store$.value);
        if ([ CALL_BETS, INSURANCE_CALLS, ].includes(roundStatus)) return null;
        return SeatId;
      }

      const state = store$.value;
      const currentPlayerSeatsIds = playerSeatsIdsSelector(state);
      const currentActiveSeatId = activeSeatIdSelector(state);

      if (MessageType === PLACE_YOUR_BETS && !currentActiveSeatId) {
        return currentPlayerSeatsIds[0];
      }

      if (currentActiveSeatId === SeatId) {
        const idx = currentPlayerSeatsIds.indexOf(SeatId);
        const nextSeatsIds = currentPlayerSeatsIds.filter((sid) => sid !== SeatId);
        return nextSeatsIds[idx] || nextSeatsIds[0];
      }

      return null;
    }),
    filter((sid) => sid !== null),
    rxMap(seats.setActiveSeatId)
  );
}

function startCallBetsEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ START_CALL_BETS, ].includes(MessageType)),
    flatMap(({ MessageType, ...rest }) => {
      const state = store$.value;
      // if insurance is placed, remove it as the dealer does not have blackjack
      const userBets = currentBetsSelector(state);
      const betsWithoutInsurance = map(over(lensProp(MAIN_BET), omit([ INSURANCE_BET, ])))(userBets);
      const actions = [ socket.set({ MessageType, ...rest, }), ];

      if (!equals(userBets, betsWithoutInsurance)) {
        actions.push(bet.apply({ current: betsWithoutInsurance, }));
      }
      return of(...actions);
    })
  );
}

function gameResultEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ GAME_RESULT, GAMERESULTS, ].includes(MessageType)),
    flatMap((socketMessage) => handleGameResult(socketMessage, store$.value))
  );
}

function wonBetsEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === WON_SIDE_BETS),
    flatMap((socketMessage) => handleWonBets(socketMessage, store$.value))
  );
}

function balanceEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CURRENT_BALANCE),
    flatMap(() => {
      const state = store$.value;
      const reservedSeatIndex = getGameParams().seatIndex;
      const actions = [];
      if (reservedSeatIndex) {
        const allSeats = nonDealerSeatsSelector(state);
        const { seats, maxSeats, } = playerSeatsSelector(state);
        // playerSeatsSelector takes into consideration ReservedSeat state as well
        const seatsTaken = seats.filter((seat) => seat.hasOwnProperty('allowsBetBehind'));
        const canTakeSeat = length(seatsTaken) < maxSeats;
        const selectedSeat = find(propEq('seatId', `s${reservedSeatIndex}`))(allSeats);

        if (canTakeSeat && selectedSeat && selectedSeat.reservedSeat) {
          actions.push(seat.take({ value: selectedSeat.seatId, }));
        }

        if (!canTakeSeat) {
          actions.push(notification.add({ message: 'messages.maximum_seats_exceeded', }));
        }

        window.history.replaceState(null, '', location.href.replace(`&seatIndex=${reservedSeatIndex}`, ''));
      }
      return of(...actions);
    }),
  );
}

function cardMessageEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CARD_MESSAGE),
    flatMap((socketMessage) => handleCardMessage(socketMessage))
  );
}

function clearAllCardsEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === 'CLEAR_ALL_CARDS'),
    rxMap(seats.clearCards)
  );
}

function cancelLastCardEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CANCEL_LAST_CARD),
    flatMap((socketMessage) => handleCancelLastCardMessage(socketMessage))
  );
}

function autoSplitEpic(action$, store$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === SPLIT_CARDS),
    flatMap((socketMessage) => handleAutoSplit(socketMessage, store$.value))
  );
}

function handleVideoModerationResult(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === VIDEO_MODERATION_RESULT),
    switchMap(() => import('../../../components/VideoChat/useAgora')),
    tap(({ default: useAgora, closeLocalTracks, }) => {
      closeLocalTracks();
      // eslint-disable-next-line no-unused-expressions
      useAgora.client?.leave?.();
    }),
    switchMap(() => action$.pipe(
      ofType(socket.message),
      first(({ payload, }) => payload?.MessageType === GAMERESULTS),
      delay(2000),
    )),
    flatMap(() => of(
      error.set({
        code: CODES?.[VIDEO_MODERATION_KICK] || 1,
        title: 'errors.video_chat_moderation_kick_title',
        description: 'errors.video_chat_moderation_kick_description',
      }),
      socket.close()
    ))
  );
}

function handleVideochatBanError(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ ErrorCode, }) => ErrorCode == 30085),
    flatMap(({ expiresAt, }) => {
      const date = new Date(expiresAt);
      date.setSeconds(0);

      return of(
        socket.error({ error: 'VIDEOCHAT_BANNED', }),
        error.set({
          code: CODES?.[VIDEO_MODERATION_BAN] || 1,
          title: 'errors.video_chat_ban_title',
          description: 'errors.video_chat_ban_description',
          variables: { expiresAt: date.toLocaleString(), },
        }),
        socket.close()
      );
    })
  );
}


function freeRoundsNotificationsEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => PLACE_YOUR_BETS === MessageType),
    rxMap(({ FreeRoundsCount, }) => FreeRoundsCount > 0),
    distinctUntilChanged(),
    switchMap((canPlayFreeRound) => {
      const state = state$.value;
      const hasSeat = playerSeatsCountSelector(state) > 0;
      const isFreeRoundsEnabled = isFreeRoundsEnabledSelector(state);

      if (!isFreeRoundsEnabled) {
        return EMPTY;
      }

      const action = dialogActions.dialog.add({
        name: DIALOG.GENERIC_DIALOG,
        title: canPlayFreeRound
          ? 'messages.free_rounds_available_title'
          : 'messages.free_rounds_not_available_title',
        description: canPlayFreeRound
          ? 'messages.free_rounds_available_desc'
          : 'messages.free_rounds_not_available_desc',
      });

      return defer(() => hasSeat
        ? of(action)
        : action$.pipe(
          skip(1),
          ofType(socket.message),
          pluck('payload'),
          filter(({ MessageType, }) => SEAT_TAKEN === MessageType),
          first(),
          rxMap(() => action)
        ));
    })
  );
}

function playerTurnResetEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === PLACE_YOUR_BETS),
    rxMap(() => playerTurn.set({
      playerTurn: {
        clientId: '',
        seatId: '',
      },
    }))
  );
}

export default combineEpics(
  // socketEpic,
  socketRequestsEpic,
  initialDataEpic,
  gameResultEpic,
  bjConfigEpic,
  handleRoundStatusEpic,
  handleRoundCancelEpic,
  handleSeatTakenEpic,
  handleSeatLeaveEpic,
  activeSeatUpdateEpic,
  startCallBetsEpic,
  balanceEpic,
  cardMessageEpic,
  clearAllCardsEpic,
  cancelLastCardEpic,
  autoSplitEpic,
  wonBetsEpic,
  handleVideoModerationResult,
  handleVideochatBanError,
  ...(isBJSPonURL ? [ freeRoundsNotificationsEpic, playerTurnResetEpic, ] : [])
);
